const CMMontalegreLogo = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="37.5"
      viewBox="0 0 443 125"
      {...props}
    >
        <path fill="currentColor" d="M291.18,57.32c.94,0,1.74-1.61,3.22-6.18s7-19.6,15-19.6,11.28,7.79,13.29,14.63,4.83,18,18.66,18,18.52-14.1,26.44-28.73,13.56-23.76,24.7-23.76,22.69,11,29.8,29.94S429.72,71,430,73.16s1.07,4.56,6.31,4.56,6.58-3.49,6.31-5.23a156.14,156.14,0,0,0-10.87-43C422.73,8.32,408,0,394.41,0s-24.57,6-34.77,28.19-13.16,27-19.06,27S331,51,327.56,40.54,319.1,24.7,310.24,24.7s-14,7.79-16.78,14.63-4.16,16.11-3.89,16.91S290.24,57.32,291.18,57.32Z"/>
        <path fill="currentColor" d="M276.1,87s8-25.34,25.88-25.34,23.71,12.3,31.43,20c6.52,6.52,16.09,9.52,28.48,5.67,10.86-3.38,15.57-8.51,31.49-8.51,15.57,0,31.25,5.25,40.9,20.94,5.68,9.24,5.55,25.1,5.55,25.1s-19.06-21.24-52.12-21.24c-24.37,0-35.49,11.4-49.35,6.88-11.1-3.62-15.08-15.08-17.25-18.7-6.61-11-15-14.72-22.44-14.72S280.56,82.42,276.1,87Z"/>
        <path fill="currentColor" d="M1.26,20.86H7.84L18.34,42.8c1.67,3.62,2,4.34,3.24,7.54a71.34,71.34,0,0,1,3.05-7.39L35.27,20.86h6.49V54.53H37.27V35.16a67.93,67.93,0,0,1,.43-10.3,51.1,51.1,0,0,1-2.53,5.91L23.68,54.53H19.29L8.75,32.68A68.43,68.43,0,0,1,5.36,25a73.35,73.35,0,0,1,.38,8.54v21H1.26Z"/><path fill="currentColor" d="M61.07,30.68c8.11,0,15.26,4,15.26,12.31,0,7.92-6.91,12.26-15.17,12.26-9.78,0-15.26-5.15-15.26-11.93,0-7.63,6.39-12.59,15.12-12.64ZM50.48,43c0,5,4.39,8.44,10.92,8.44,5.44,0,10.35-3.2,10.35-8.49S67,34.31,60.83,34.4C54.06,34.5,50.48,38.55,50.48,43Z"/>
        <path fill="currentColor" d="M85.58,34.07a11,11,0,0,1,3.86-2.38,17.46,17.46,0,0,1,6.49-1.14c4.1,0,8.16,1.19,10.49,4.1,1.81,2.29,1.86,4.77,1.86,7.3V54.53H103.8V42.22c0-2.05-.1-4.2-2.24-5.91a10,10,0,0,0-6.39-1.86,12.25,12.25,0,0,0-5.15,1c-4.39,2-4.44,5.53-4.44,8V54.53H81.05V31.25h4.53v2.81Z"/>
        <path fill="currentColor" d="M115.25,23.24h4.48v8h6.53v3.15h-6.58l0,12.5c0,2.34,0,4.05,3.2,4.05a16.7,16.7,0,0,0,3.29-.38v4c-1.48.14-3,.33-4.48.33-6.39,0-6.34-3.2-6.39-6.72V34.31l-4,0V31.21h4v-8Z"/>
        <path fill="currentColor" d="M129,36.45c1-2.72,2.19-5.91,12.54-5.91a30.25,30.25,0,0,1,7.06.81c5.2,1.43,5.2,4.77,5.2,7.34v8.44c0,3.1,0,4.39,2.58,4.39.57,0,1.14-.1,1.72-.14v3.1c-1.48.14-2.81.29-4,.29-4.05,0-4.29-2.29-4.48-4a7.81,7.81,0,0,1-3.15,2.86,17.67,17.67,0,0,1-7.92,1.72c-9.59,0-11.07-5.44-11.07-8a6.68,6.68,0,0,1,5.34-6.68c2.38-.62,7-.67,10.11-.67h6.58c0-2.48,0-4.34-2.43-5.39a13.64,13.64,0,0,0-5.25-.81,15,15,0,0,0-5.67.86,4.22,4.22,0,0,0-2.58,2.19Zm12.07,7.06c-2.67,0-5.15,0-6.63.62a3.37,3.37,0,0,0-2.43,3.2c0,2.15,1.86,4.58,6.68,4.58.76,0,4.77-.1,7.63-2a7,7,0,0,0,3.24-6.39Z"/>
        <path fill="currentColor" d="M160.89,20.86h4.48V54.53h-4.48Z"/>
        <path fill="currentColor" d="M198.14,47.76a11,11,0,0,1-4,4.77,18.15,18.15,0,0,1-9.83,2.72c-7.39,0-14.26-4.06-14.26-12.5,0-7.25,5.58-12.26,14.12-12.26s14.21,5.58,14.4,12.93h-23.9A8.3,8.3,0,0,0,176.44,48a11.6,11.6,0,0,0,14.5,1.91,8.27,8.27,0,0,0,2.86-3.34ZM193.51,40c-.57-1.48-2.29-6.15-9-6.15-7.11,0-9.06,4.82-9.63,6.15Z"/>
        <path fill="currentColor" d="M226.18,50.76a15.5,15.5,0,0,1-11,4c-6.91,0-13.54-3.91-13.54-11.83,0-6.82,4.77-12.07,14-12.07,6.11,0,9.11,2.15,10.54,3.15V31.3h4.34V53.15c0,2.48,0,5.2-1.91,7.39-2.86,3.2-8.39,4-12.73,4a24.53,24.53,0,0,1-7.53-1c-5.15-1.76-5.44-5.44-5.63-7.44l4.72-.19c0,1.19.1,3,3.39,4.15a20.41,20.41,0,0,0,5.58.72,16.07,16.07,0,0,0,6-1c3.77-1.53,3.77-4.15,3.77-6.25V50.76Zm-19.75-7.87c0,5.1,4.15,8.06,9.68,8.06,6.29,0,10-4,10-8.35,0-5.63-5.2-8.25-10.16-8.11C210.78,34.64,206.44,37.55,206.44,42.89Z"/>
        <path fill="currentColor" d="M236.87,31.25h4.53v2.62c3.34-3.2,7.92-3.29,9-3.29a11.48,11.48,0,0,1,2.72.33V35.4a11.27,11.27,0,0,0-3.58-.57,12.37,12.37,0,0,0-4.1.62c-4,1.48-4,4.67-4,6.44V54.53h-4.58V31.25Z"/>
        <path fill="currentColor" d="M281.09,47.76a11,11,0,0,1-4,4.77,18.15,18.15,0,0,1-9.83,2.72c-7.39,0-14.26-4.06-14.26-12.5,0-7.25,5.58-12.26,14.12-12.26s14.21,5.58,14.4,12.93h-23.9A8.3,8.3,0,0,0,259.39,48a11.6,11.6,0,0,0,14.5,1.91,8.27,8.27,0,0,0,2.86-3.34ZM276.46,40c-.57-1.48-2.29-6.15-9-6.15-7.11,0-9.06,4.82-9.63,6.15Z"/>
        <path fill="currentColor" d="M17.2,73.68c-1.12-2.85-3.43-4.31-6.76-4.31-4.94,0-8.71,3.55-8.71,8.27a7,7,0,0,0,1,3.87A7.65,7.65,0,0,0,9.24,84.6a8.43,8.43,0,0,0,5.6-1.95,8.18,8.18,0,0,0,1.9-2.24l1.34.8a10,10,0,0,1-9,4.77C3.65,86,0,82.78,0,78c0-5.77,4.45-10,10.58-10,3.94,0,6.62,1.63,8.2,5Z"/>
        <path fill="currentColor" d="M32.14,85.53c-.27,0-.12,0-.78.07-.22,0-.41,0-.54,0-1.14,0-1.75-.51-1.75-1.48V83.8c-1.34,1.36-3.58,2.07-6.64,2.07s-4.7-1.26-4.7-3.48A3.83,3.83,0,0,1,20,78.79a10.7,10.7,0,0,1,2.87-.73,46,46,0,0,1,6.86-.29h.71a5.44,5.44,0,0,0,.15-1,1.45,1.45,0,0,0-.73-1.39,9,9,0,0,0-4.16-.75c-3,0-4.36.73-5,2.63l-1.65-.1a4.81,4.81,0,0,1,1.44-2.43c1.12-1,2.77-1.43,5.35-1.43,4.28,0,6.23,1,6.23,3.21a7.14,7.14,0,0,1-.19,1.41l-1.07,4.7a3.86,3.86,0,0,0-.15.92c0,.61.29.8,1.17.8h.54Zm-3.67-6.47c-1.48,0-3.67.12-5,.27a7.8,7.8,0,0,0-2.8.66,2.44,2.44,0,0,0-1.24,2.19c0,1.61,1.14,2.34,3.6,2.34,2.94,0,5-.83,6.06-2.41a6.18,6.18,0,0,0,.66-1.34,9,9,0,0,0,.44-1.68C29.42,79.08,28.86,79.05,28.47,79.05Zm-.22-6.86-1.46-2.07-2.56,2.07H22.87L26.18,69h1.43l1.83,3.21Z"/>
        <path fill="currentColor" d="M59,80.22l-1.22,5.33H56.18l1.19-5.23a10.63,10.63,0,0,0,.29-2.19c0-2.16-1.53-3.38-4.31-3.38a6.35,6.35,0,0,0-4.28,1.41A4.38,4.38,0,0,0,48,77.69a11.18,11.18,0,0,0-.75,2.53L46,85.55H44.38l1.19-5.23a10.63,10.63,0,0,0,.29-2.19c0-2.16-1.53-3.38-4.31-3.38a6.35,6.35,0,0,0-4.28,1.41,4.56,4.56,0,0,0-1.12,1.53,7.62,7.62,0,0,0-.63,1.92l-1.34,5.94H32.58l2.72-11.9h1.61l-.37,1.68a6.63,6.63,0,0,1,5.21-2,7.68,7.68,0,0,1,3.84.9,3.68,3.68,0,0,1,1.68,2.09,6.33,6.33,0,0,1,2.41-2.16,9.66,9.66,0,0,1,3.92-.83c3.62,0,5.72,1.68,5.72,4.53A10.75,10.75,0,0,1,59,80.22Z"/>
        <path fill="currentColor" d="M74.58,85.53c-.27,0-.12,0-.78.07-.22,0-.41,0-.54,0-1.14,0-1.75-.51-1.75-1.48V83.8c-1.34,1.36-3.58,2.07-6.64,2.07s-4.7-1.26-4.7-3.48a3.83,3.83,0,0,1,2.31-3.6,10.7,10.7,0,0,1,2.87-.73,46,46,0,0,1,6.86-.29h.71a5.44,5.44,0,0,0,.15-1,1.45,1.45,0,0,0-.73-1.39,9,9,0,0,0-4.16-.75c-3,0-4.36.73-5,2.63l-1.65-.1A4.81,4.81,0,0,1,63,74.7c1.12-1,2.77-1.43,5.35-1.43,4.28,0,6.23,1,6.23,3.21a7.14,7.14,0,0,1-.19,1.41l-1.07,4.7a3.86,3.86,0,0,0-.15.92c0,.61.29.8,1.17.8h.54Zm-3.67-6.47c-1.48,0-3.67.12-5,.27a7.8,7.8,0,0,0-2.8.66,2.44,2.44,0,0,0-1.24,2.19c0,1.61,1.14,2.34,3.6,2.34,2.94,0,5-.83,6.06-2.41a6.18,6.18,0,0,0,.66-1.34,9,9,0,0,0,.44-1.68C71.86,79.08,71.3,79.05,70.91,79.05Z"/>
        <path fill="currentColor" d="M85.07,75a12.28,12.28,0,0,0-1.43-.1,6,6,0,0,0-3.89,1.34A3.88,3.88,0,0,0,79,77a5.24,5.24,0,0,0-1,2.38l-1.39,6.15H75l2.72-11.9h1.61l-.44,1.95A5.45,5.45,0,0,1,81,73.92a9,9,0,0,1,3.55-.66c.24,0,.44,0,.92,0Z"/>
        <path fill="currentColor" d="M98,85.53c-.27,0-.12,0-.78.07-.22,0-.41,0-.54,0-1.14,0-1.75-.51-1.75-1.48V83.8c-1.34,1.36-3.58,2.07-6.64,2.07s-4.7-1.26-4.7-3.48a3.83,3.83,0,0,1,2.31-3.6,10.7,10.7,0,0,1,2.87-.73,46,46,0,0,1,6.86-.29h.71a5.44,5.44,0,0,0,.15-1,1.45,1.45,0,0,0-.73-1.39,9,9,0,0,0-4.16-.75c-3,0-4.36.73-5,2.63L85,77.13a4.81,4.81,0,0,1,1.44-2.43c1.12-1,2.77-1.43,5.35-1.43,4.28,0,6.23,1,6.23,3.21a7.14,7.14,0,0,1-.19,1.41l-1.07,4.7a3.86,3.86,0,0,0-.15.92c0,.61.29.8,1.17.8h.54Zm-3.67-6.47c-1.48,0-3.67.12-5,.27a7.8,7.8,0,0,0-2.8.66,2.44,2.44,0,0,0-1.24,2.19c0,1.61,1.14,2.34,3.6,2.34,2.94,0,5-.83,6.06-2.41a6.18,6.18,0,0,0,.66-1.34A9,9,0,0,0,96,79.08C95.29,79.08,94.73,79.05,94.34,79.05Z"/>
        <path fill="currentColor" d="M123.84,85.55H122.3l3.53-15.28L115.3,85.55h-1.7l-3.53-15.3-3.53,15.3H105l4-17.18h2.12l3.67,15.72,10.85-15.72h2.21Z"/>
        <path fill="currentColor" d="M140.22,85.55h-1.61l.37-1.68c-1.12,1.34-2.92,2-5.4,2-3.89,0-6-1.58-6-4.53a11.08,11.08,0,0,1,.32-2.38l1.19-5.33h1.61l-1.17,5.26a12.43,12.43,0,0,0-.29,2.16c0,2.24,1.53,3.41,4.5,3.41A6.44,6.44,0,0,0,138.24,83a4.23,4.23,0,0,0,1.12-1.51,7.37,7.37,0,0,0,.61-1.95l1.34-5.94h1.61Z"/>
        <path fill="currentColor" d="M157.47,80.22l-1.22,5.33h-1.61l1.19-5.23a10.64,10.64,0,0,0,.29-2.16c0-2.26-1.53-3.41-4.5-3.41a6.51,6.51,0,0,0-4.48,1.41A4.56,4.56,0,0,0,146,77.69a7.62,7.62,0,0,0-.63,1.92l-1.34,5.94h-1.61l2.72-11.9h1.61l-.37,1.68c1.12-1.34,2.92-2,5.4-2,3.87,0,6,1.58,6,4.5A10.83,10.83,0,0,1,157.47,80.22Z"/>
        <path fill="currentColor" d="M160.08,85.55h-1.61l2.77-11.9h1.61Zm3.28-14.16h-1.61l.54-2.34h1.61Z"/>
        <path fill="currentColor" d="M175.79,77.77a4.47,4.47,0,0,0-1.87-2.14,5.92,5.92,0,0,0-3.41-.92c-3.67,0-6.06,2.12-6.06,5.38,0,2.7,2,4.43,5.18,4.43a6.54,6.54,0,0,0,4.48-1.61,8.14,8.14,0,0,0,1.46-1.9l1.51.68a8.35,8.35,0,0,1-7.61,4.21c-4.06,0-6.69-2.21-6.69-5.64,0-4,3.26-6.93,7.79-6.93,3.16,0,5.64,1.39,6.79,3.82Z"/>
        <path fill="currentColor" d="M178.89,85.55h-1.61l2.77-11.9h1.61Zm3.28-14.16h-1.61l.54-2.34h1.61Z"/>
        <path fill="currentColor" d="M188.94,86c-2.7,0-4.48-.78-5.77-2.51l-1.51,6.64h-1.61l3.8-16.45h1.61l-.37,1.56a5.17,5.17,0,0,1,2.16-1.34,9.66,9.66,0,0,1,3.31-.54c3.87,0,6.35,2.17,6.35,5.57C196.92,83.07,193.63,86,188.94,86Zm1.31-11.22a6.88,6.88,0,0,0-4.36,1.31A5.92,5.92,0,0,0,184,80.49c0,2.56,1.87,4.06,5,4.06,3.65,0,6.11-2.24,6.11-5.57C195.16,76.31,193.36,74.75,190.25,74.75Z"/>
        <path fill="currentColor" d="M211.71,85.53c-.27,0-.12,0-.78.07-.22,0-.41,0-.54,0-1.14,0-1.75-.51-1.75-1.48V83.8c-1.34,1.36-3.58,2.07-6.64,2.07s-4.7-1.26-4.7-3.48a3.83,3.83,0,0,1,2.31-3.6,10.7,10.7,0,0,1,2.87-.73,46,46,0,0,1,6.86-.29h.71a5.44,5.44,0,0,0,.15-1,1.45,1.45,0,0,0-.73-1.39,9,9,0,0,0-4.16-.75c-3,0-4.36.73-5,2.63l-1.65-.1a4.81,4.81,0,0,1,1.44-2.43c1.12-1,2.77-1.43,5.35-1.43,4.28,0,6.23,1,6.23,3.21a7.14,7.14,0,0,1-.19,1.41l-1.07,4.7a3.86,3.86,0,0,0-.15.92c0,.61.29.8,1.17.8H212ZM208,79.05c-1.48,0-3.67.12-5,.27a7.8,7.8,0,0,0-2.8.66A2.44,2.44,0,0,0,199,82.17c0,1.61,1.14,2.34,3.6,2.34,2.94,0,5-.83,6.06-2.41a6.18,6.18,0,0,0,.66-1.34,9,9,0,0,0,.44-1.68C209,79.08,208.43,79.05,208,79.05Z"/>
        <path fill="currentColor" d="M213.78,85.55h-1.61l4-17.18h1.61Z"/>
    </svg>
  );

  export default CMMontalegreLogo;