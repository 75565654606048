const Mcoutinho = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%" 
      height="100%" 
      viewBox="0 0 5508 2481"
      fill="currentColor"
      {...props}
    >
        <g transform="matrix(4.16667,0,0,4.16667,0,0)">
        <g transform="matrix(1,0,0,1,291.301,249.719)">
            <path d="M0,54.793L7.225,-2.354C7.283,-2.858 7.535,-3.11 7.985,-3.11L8.571,-3.11C8.907,-3.11 9.243,-2.858 9.58,-2.354L36.811,40.253L64.039,-2.354C64.375,-2.858 64.714,-3.11 65.047,-3.11L65.637,-3.11C66.083,-3.11 66.335,-2.858 66.394,-2.354L73.538,54.793C73.705,56.141 73.032,56.812 71.519,56.812L63.619,56.812C62.89,56.812 62.401,56.687 62.149,56.432C61.896,56.183 61.714,55.637 61.603,54.793L58.409,25.379L39.33,55.889C38.603,57.232 37.904,57.903 37.231,57.903L36.306,57.903C35.856,57.903 35.158,57.232 34.207,55.889L15.462,25.63L11.935,54.793C11.819,55.689 11.652,56.254 11.43,56.476C11.204,56.698 10.7,56.812 9.916,56.812L2.016,56.812C0.503,56.812 -0.168,56.141 0,54.793" styles="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,418.71,263.078)">
            <path d="M0,28.073L4.455,33.031C5.013,33.702 5.32,34.18 5.378,34.459C5.433,34.744 5.212,35.216 4.707,35.892C2.014,38.521 -1.303,40.625 -5.254,42.191C-9.204,43.761 -13.225,44.545 -17.313,44.545C-25.551,44.545 -32.668,41.548 -38.66,35.553C-44.6,29.616 -47.568,22.441 -47.568,14.038C-47.568,5.635 -44.6,-1.538 -38.66,-7.478C-32.668,-13.472 -25.551,-16.472 -17.313,-16.472C-13.225,-16.472 -9.204,-15.686 -5.254,-14.116C-1.303,-12.547 2.014,-10.446 4.707,-7.816C5.212,-7.253 5.433,-6.807 5.378,-6.469C5.32,-6.132 5.013,-5.63 4.455,-4.956L-0.085,0.002C-0.702,0.619 -1.207,0.925 -1.598,0.925C-1.99,0.925 -2.437,0.673 -2.941,0.172C-6.921,-4.033 -11.71,-6.132 -17.313,-6.132C-22.691,-6.132 -27.063,-4.116 -30.424,-0.081C-33.787,3.952 -35.468,8.661 -35.468,14.038C-35.468,19.304 -33.787,23.984 -30.424,28.073C-27.006,32.162 -22.636,34.207 -17.313,34.207C-11.766,34.207 -6.95,32.082 -2.859,27.821C-2.355,27.316 -1.906,27.064 -1.514,27.064C-1.067,27.064 -0.562,27.402 0,28.073" styles="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,457.622,257.033)">
            <path d="M0,40.169C5.489,40.169 9.86,38.154 13.109,34.118C16.417,30.026 18.07,25.35 18.07,20.083C18.07,14.763 16.417,10.057 13.109,5.961C9.86,1.929 5.489,-0.086 0,-0.086C-5.323,-0.086 -9.694,1.959 -13.113,6.047C-16.473,10.084 -18.155,14.763 -18.155,20.083C-18.155,25.35 -16.473,30.026 -13.113,34.118C-9.638,38.154 -5.268,40.169 0,40.169M0,50.593C-8.235,50.593 -15.354,47.593 -21.348,41.599C-27.288,35.661 -30.255,28.486 -30.255,20.083C-30.255,11.677 -27.288,4.507 -21.348,-1.433C-15.354,-7.427 -8.235,-10.424 0,-10.424C8.181,-10.424 15.27,-7.427 21.263,-1.433C27.2,4.507 30.171,11.677 30.171,20.083C30.171,28.486 27.2,35.661 21.263,41.599C15.27,47.593 8.181,50.593 0,50.593" styles="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,496.87,272.998)">
            <path d="M0,9.328L0,-23.279C0,-24.066 0.14,-24.597 0.418,-24.879C0.697,-25.155 1.234,-25.297 2.018,-25.297L9.917,-25.297C10.7,-25.297 11.234,-25.155 11.517,-24.879C11.795,-24.597 11.935,-24.066 11.935,-23.279L11.935,8.91C11.935,19.162 16.306,24.287 25.047,24.287C33.785,24.287 38.153,19.162 38.153,8.91L38.153,-23.279C38.153,-24.066 38.293,-24.597 38.578,-24.879C38.856,-25.155 39.388,-25.297 40.172,-25.297L48.073,-25.297C48.857,-25.297 49.392,-25.155 49.67,-24.879C49.949,-24.597 50.088,-24.066 50.088,-23.279L50.088,9.328C50.088,17.341 47.709,23.563 42.946,27.987C38.293,32.411 32.329,34.625 25.047,34.625C17.818,34.625 11.818,32.411 7.06,27.987C2.351,23.503 0,17.287 0,9.328" styles="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,574.358,249.719)">
            <path d="M0,54.793L0,8.655L-17.48,8.655C-18.267,8.655 -18.795,8.501 -19.077,8.192C-19.359,7.886 -19.498,7.34 -19.498,6.551L-19.498,-0.001C-19.498,-0.788 -19.359,-1.318 -19.077,-1.597C-18.795,-1.876 -18.267,-2.019 -17.48,-2.019L29.418,-2.019C30.202,-2.019 30.732,-1.876 31.012,-1.597C31.291,-1.318 31.433,-0.788 31.433,-0.001L31.433,6.551C31.433,7.34 31.291,7.886 31.012,8.192C30.732,8.501 30.202,8.655 29.418,8.655L11.935,8.655L11.935,54.793C11.935,55.58 11.796,56.114 11.513,56.393C11.234,56.672 10.703,56.812 9.92,56.812L2.018,56.812C1.234,56.812 0.7,56.672 0.419,56.393C0.14,56.114 0,55.58 0,54.793" styles="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,625.121,247.698)">
            <path d="M0,58.833L-7.9,58.833C-8.683,58.833 -9.217,58.693 -9.496,58.414C-9.777,58.136 -9.918,57.599 -9.918,56.814L-9.918,2.018C-9.918,1.234 -9.777,0.703 -9.496,0.424C-9.217,0.145 -8.683,0 -7.9,0L0,0C0.782,0 1.317,0.145 1.599,0.424C1.878,0.703 2.018,1.234 2.018,2.018L2.018,56.814C2.018,57.599 1.878,58.136 1.599,58.414C1.317,58.693 0.782,58.833 0,58.833" styles="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,638.231,249.719)">
            <path d="M0,54.793L0,-2.354C0,-2.858 0.251,-3.11 0.756,-3.11L1.513,-3.11C1.846,-3.11 2.323,-2.885 2.94,-2.44L39.5,33.447L39.5,-0.001C39.5,-0.788 39.64,-1.318 39.919,-1.597C40.197,-1.876 40.731,-2.019 41.518,-2.019L49.334,-2.019C50.117,-2.019 50.664,-1.876 50.972,-1.597C51.28,-1.318 51.435,-0.788 51.435,-0.001L51.435,57.149C51.435,57.654 51.18,57.903 50.676,57.903L49.921,57.903C49.474,57.903 48.969,57.654 48.408,57.149L11.935,20.925L11.935,54.793C11.935,55.58 11.795,56.114 11.513,56.393C11.233,56.672 10.7,56.812 9.916,56.812L2.018,56.812C1.233,56.812 0.697,56.672 0.418,56.393C0.14,56.114 0,55.58 0,54.793" styles="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,700.003,249.719)">
            <path d="M0,54.793L0,-0.001C0,-0.788 0.14,-1.318 0.419,-1.597C0.698,-1.876 1.231,-2.019 2.016,-2.019L9.917,-2.019C10.7,-2.019 11.231,-1.876 11.511,-1.597C11.796,-1.318 11.935,-0.788 11.935,-0.001L11.935,22.438L37.987,22.438L37.987,-0.001C37.987,-0.788 38.127,-1.318 38.406,-1.597C38.688,-1.876 39.217,-2.019 40.002,-2.019L47.904,-2.019C48.688,-2.019 49.22,-1.876 49.499,-1.597C49.779,-1.318 49.922,-0.788 49.922,-0.001L49.922,54.793C49.922,55.58 49.779,56.114 49.499,56.393C49.22,56.672 48.688,56.812 47.904,56.812L40.002,56.812C39.217,56.812 38.688,56.672 38.406,56.393C38.127,56.114 37.987,55.58 37.987,54.793L37.987,32.773L11.935,32.773L11.935,54.793C11.935,55.58 11.796,56.114 11.511,56.393C11.231,56.672 10.7,56.812 9.917,56.812L2.016,56.812C1.231,56.812 0.698,56.672 0.419,56.393C0.14,56.114 0,55.58 0,54.793" styles="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,789.088,257.033)">
            <path d="M0,40.169C5.49,40.169 9.861,38.154 13.113,34.118C16.419,30.026 18.071,25.35 18.071,20.083C18.071,14.763 16.419,10.057 13.113,5.961C9.861,1.929 5.49,-0.086 0,-0.086C-5.323,-0.086 -9.691,1.959 -13.109,6.047C-16.472,10.084 -18.153,14.763 -18.153,20.083C-18.153,25.35 -16.472,30.026 -13.109,34.118C-9.638,38.154 -5.264,40.169 0,40.169M0,50.593C-8.233,50.593 -15.353,47.593 -21.343,41.599C-27.284,35.661 -30.255,28.486 -30.255,20.083C-30.255,11.677 -27.284,4.507 -21.343,-1.433C-15.353,-7.427 -8.233,-10.424 0,-10.424C8.181,-10.424 15.271,-7.427 21.264,-1.433C27.202,4.507 30.172,11.677 30.172,20.083C30.172,28.486 27.202,35.661 21.264,41.599C15.271,47.593 8.181,50.593 0,50.593" styles="fill:white;fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,127.359,314.414)">
            <path d="M0,-74.773L-30.101,-104.79L-70.616,16.229L69.029,-123.333C70.87,-125.167 73.308,-125.739 75.833,-125.14C78.358,-124.537 80.35,-122.374 81.165,-119.906L133.919,39.203C136.247,43.684 133.176,47.364 132.726,47.766C129.128,51.025 124.807,50.111 122.368,47.623L35.623,-39.148C32.821,-41.954 32.821,-46.5 35.623,-49.305L35.625,-49.308L35.625,-49.325L40.698,-54.396L109.408,14.259L71.217,-105.247L-81.588,47.594C-84.046,50.045 -87.565,50.64 -90.515,48.811C-93.469,46.984 -94.654,43.494 -93.553,40.201L-40.257,-120.279C-39.434,-122.743 -37.398,-124.603 -34.873,-125.199C-32.345,-125.798 -29.785,-124.873 -28.314,-123.454L5.339,-89.678C7.599,-87.384 8.356,-83.09 5.12,-79.856L0,-74.773Z" styles="fill:white;fill-rule:nonzero;"/>
        </g>
    </g>
    </svg>
  );

  export default Mcoutinho;