import React from 'react';

const Rules = () => (
  <div className="rules-container">
    <h2>"GIVEAWAY - Montalegre World RX of Portugal 2024”</h2>
    <h3>Regulamento - termos e condições de Passatempo</h3>
    <p>O presente Passatempo é promovido pelo Circuito Internacional de Montalegre. Os seguintes Termos e Condições destinam-se a regular a participação no Passatempo “GIVEAWAY - Montalegre World RX of Portugal”, no qual a organização vai oferecer 10 bilhetes duplos. Todos os participantes concordam implicitamente com os presentes Termos e Condições, aceitando sujeitar-se aos mesmos.
    </p>

    <h4>1. Descrição geral</h4>
    <p>O Passatempo ocorrerá entre o dia 01 de setembro até às 23:59h de 06 de setembro 2024,. Será disponibilizada no Facebook do Circuito Internacional de Montalegre uma imagem onde estão escondidos os prémios que temos para oferecer. Os utilizadores serão convidados a encontrar as referidas contas, clicando num dos quadrados que compõe a imagem presente no facebook.
    </p>

    <h4>2. Como Participar?</h4>
    <p>
      Os utilizadores devem:
    </p>
    <ul>
      <li>
        2.1.1. Aceder à aplicação MontalegreRX disponível no Facebook do
        Circuito Internacional de Montalegre.
      </li>
      <li>
        2.1.2. Fazer a ligação com o Facebook. Cada perfil de Facebook só
        poderá jogar uma vez por por dia no passatempo. Para participar, é
        necessário seguir a página oficial do Circuito Internacional de Montalegre,
        no Facebook.
      </li>
      <li>
        2.1.3. Depois de feito o login no Facebook, os utilizadores poderão tentar
        encontrar os prémios escondidos na imagem clicando nos quadrados
        disponíveis na mesma.
      </li>
      <li>
        2.1.4. No caso de não acertar, o utilizador pode convidar os seus amigos a
        participarem. No caso de um dos seus amigos acertar num prémio
        acedendo ao site através do convite efectuado pelo utilizador são
        premiados os dois utilizadores.
      </li>
      <li>
        2.2 Na eventualidade dos participantes premiados não levantarem os seus
        prémios, o Circuito Internacional de Montalegre reserva-se o direito de os
        utilizar para fins próprios.
      </li>
    </ul>

    <h4>3. Quem pode participar?</h4>
    <ul>
      <li>
        3.1 Utilizadores com contas criadas no Facebook, até 01 de maio de 2024.
        Estes utilizadores têm que seguir a página oficial do Circuito Internacional de
        Montalegre, no Facebook.
      </li>
    </ul>

    <h4>4. Os Prémios</h4>
    <ul>
      <li>
        4.1 Os prémios a atribuir aos utilizadores no passatempo são: <br />
        - 10 bilhetes duplos para MONTALEGRE WORLD RX OF PORTUGAL 2024  <br />
      </li>
      <li>
        4.2 Cada utilizador só poderá jogar uma vez por dia.
      </li>
      <li>
        4.3 Cada utilizador só pode vencer um prémio.
      </li>
      <li>
        4.3 Os prémios não poderão ser trocados por dinheiro.
      </li>
    </ul>

    <h4>5. Entrega do Prémio</h4>
    <ul>
      <li>
        5.1 Será enviado um email com o código e intruções de utilização.
      </li>
    </ul>

    <h4>6. Disposições finais</h4>
    <ul>
      <li>
        6.1 O Circuito Internacional de Montalegre reserva-se no direito de, após
        verificar a existência de qualquer violação do presente regulamento ou qualquer
        indício de participação fraudulenta desclassificar o participante em causa. A cada
        utilizador só poderá corresponder uma conta de Facebook, pelo que a criação
        de múltiplas contas para efeitos de participação implicará a desclassificação do
        utilizador.
      </li>
      <li>
        6.2 É proibida a participação no presente passatempo para fins contrários à Lei,
        que suponham um prejuízo para terceiros, ou de qualquer forma lesem a sua
        honra, dignidade, imagem, intimidade, crenças religiosas, ideologias ou
        qualquer outro direito reconhecido legalmente.
      </li>
      <li>
        6.3. Os participantes autorizam o Circuito Internacional de Montalegre a divulgar
        a sua identificação e imagem através dos diferentes meios de comunicação do
        evento.
      </li>
      <li>
        6.4. O Circuito Internacional de Montalegre apenas procede à recolha dos dados
        pessoais dos registos que recebe para efeitos de selecção dos vencedores. São a
        todo o tempo assegurados aos titulares dos dados os direitos de acesso e
        rectificação previstos na lei, bastando, para o efeito, entrar em contacto com a
        organização do evento.
      </li>
      <li>
        O Circuito Internacional de Montalegre reserva-se o direito de alterar os
        presentes Termos e Condições sem necessidade de aviso prévio, mediante a
        publicação dos novos Termos e Condições no seguinte endereço electrónico:&nbsp;
        <a style={{ color: '#fff' }} href="https://www.montalegrecircuit.pt/privacy-policy/">https://www.montalegrecircuit.pt/</a> - wrx@cm-montalegre.pt
      </li>
      <li>
        6.6 Todas as dúvidas sobre a interpretação dos termos e condições e casos
        omissos relativos ao passatempo serão analisadas e decididas pela organização
        do Circuito Internacional de Montalegre em conjunto com a empresa Purple
        Profile.
      </li>
    </ul>
  </div>
);

export default Rules;
