const Volvo = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg"
		width="230" 
		height="50" 
		viewBox="0 0 5502.000000 2023.000000"
		color="currentColor"
		{...props}
	>

<g transform="translate(-20.000000,3.000000) scale(10,10)"
fill="currentColor" stroke="none">
<path class="st0" d="M36.24,66.83L21.66,25.91h8.7l6.54,19.8c2.64,7.74,3.48,10.62,4.08,13.2h0.12c0.6-2.4,1.5-5.64,4.02-13.26
		l6.3-19.74h8.52L45.3,66.83H36.24z"/>
	<path class="st0" d="M74.7,67.67c-9.36,0-15-5.88-15-15.48c0-9.48,6-15.66,15.24-15.66c9.36,0,15,5.88,15,15.54
		C89.94,61.55,83.94,67.67,74.7,67.67z M74.82,42.59c-4.74,0-7.5,3.84-7.5,9.54c0,5.64,2.76,9.54,7.5,9.54c4.74,0,7.5-3.9,7.5-9.54
		C82.32,46.43,79.56,42.59,74.82,42.59z"/>
	<path class="st0" d="M95.82,24.47h7.2v42.36h-7.2V24.47z"/>
	<path class="st0" d="M118.74,66.83l-11.1-29.4h8.16l4.08,11.88c1.32,3.9,2.58,8.16,3.12,10.5h0.18c0.54-2.34,1.8-6.6,3.06-10.5
		l3.84-11.88h7.98l-11.1,29.4H118.74z"/>
	<path class="st0" d="M154.86,67.67c-9.36,0-15-5.88-15-15.48c0-9.48,6-15.66,15.24-15.66c9.36,0,15,5.88,15,15.54
		C170.1,61.55,164.1,67.67,154.86,67.67z M154.98,42.59c-4.74,0-7.5,3.84-7.5,9.54c0,5.64,2.76,9.54,7.5,9.54
		c4.74,0,7.5-3.9,7.5-9.54C162.48,46.43,159.72,42.59,154.98,42.59z"/>
	<path class="st0" d="M227.58,54.95c-2.52,8.4-9,12.84-17.82,12.84c-11.46,0-19.62-8.1-19.62-21.36c0-14.58,10.02-21.48,19.62-21.48
		c8.28,0,14.58,4.14,17.34,12.12l-7.38,2.1c-1.68-4.74-5.34-7.26-10.08-7.26c-6.12,0-11.28,4.98-11.28,14.4
		c0,8.76,4.56,14.46,11.52,14.46c5.16,0,8.88-2.7,10.44-7.68L227.58,54.95z"/>
	<path class="st0" d="M246.48,67.67c-9.36,0-15-5.88-15-15.48c0-9.48,6-15.66,15.24-15.66c9.36,0,15,5.88,15,15.54
		C261.72,61.55,255.72,67.67,246.48,67.67z M246.6,42.59c-4.74,0-7.5,3.84-7.5,9.54c0,5.64,2.76,9.54,7.5,9.54
		c4.74,0,7.5-3.9,7.5-9.54C254.1,46.43,251.34,42.59,246.6,42.59z"/>
	<path class="st0" d="M286.92,66.83v-15.6c0-3.18-0.06-4.62-0.78-6.12c-0.78-1.5-2.28-2.7-4.98-2.7c-1.92,0-3.6,0.72-4.5,1.68
		c-1.92,1.92-2.46,4.62-2.46,8.28v14.46H267v-14.4c0-9.3-0.12-12.42-0.18-15h6.78l0.12,3.9h0.06c1.92-3.06,5.4-4.8,9.42-4.8
		c6.78,0,10.92,3.78,10.92,12.06v18.24H286.92z"/>
	<path class="st0" d="M302.7,58.13c3.36,2.58,7.08,3.9,10.68,3.9c3.42,0,5.1-1.2,5.1-3.18c0-6.06-17.64-1.02-17.64-13.32
		c0-5.58,4.68-9,11.94-9c3.9,0,8.16,1.14,11.46,3.3l-2.76,5.04c-2.58-1.86-5.76-2.88-8.82-2.88c-3.12,0-4.5,1.2-4.5,2.82
		c0,6,17.7,0.96,17.7,13.38c0,5.94-5.04,9.48-12.54,9.48c-4.74,0-9.3-1.38-13.56-4.32L302.7,58.13z"/>
	<path class="st0" d="M328.62,43.19v-5.76h4.68v-9.12l7.2-1.44v10.56h7.44v5.76h-7.44v11.58c0,4.44,0.18,5.34,0.9,6.12
		c0.54,0.54,1.2,0.72,2.4,0.72c1.5,0,3.12-0.24,4.14-0.66v5.7c-1.44,0.6-3.78,1.02-5.94,1.02c-6.24,0-8.7-2.82-8.7-9.6V43.19H328.62
		z"/>
	<path class="st0" d="M353.88,66.83v-14.4c0-9.3-0.12-12.42-0.18-15h6.78l0.18,5.28h0.06c2.46-4.38,5.64-6.12,9.24-6.12
		c0.6,0,1.26,0,1.62,0.06v7.38c-0.48-0.06-1.32-0.06-1.74-0.06c-2.4,0-4.5,0.72-5.94,2.16c-2.7,2.64-2.82,6.18-2.82,10.74v9.96
		H353.88z"/>
	<path class="st0" d="M396.66,66.83l-0.12-3.9h-0.06c-1.92,3.06-5.34,4.74-9.24,4.74c-6.66,0-10.8-3.72-10.8-12V37.43h7.2v15.6
		c0,3.18,0.06,4.62,0.78,6.12c0.78,1.5,2.28,2.7,4.8,2.7c1.86,0,3.48-0.78,4.44-1.68c1.92-1.92,2.4-4.68,2.4-8.28V37.43h7.2v14.88
		c0,8.82,0.12,12.06,0.18,14.52H396.66z"/>
	<path class="st0" d="M436.74,58.73c-2.58,5.46-6.54,8.94-13.62,8.94c-8.7,0-14.58-5.64-14.58-15.48c0-10.02,6.3-15.66,14.64-15.66
		c6.6,0,11.04,2.94,13.14,8.52l-6.54,2.64c-1.08-3.18-3.24-5.16-6.6-5.16c-4.2,0-7.02,3.36-7.02,9.54c0,5.58,2.58,9.6,7.2,9.6
		c3.42,0,5.58-1.98,6.84-5.16L436.74,58.73z"/>
	<path class="st0" d="M439.86,43.19v-5.76h4.68v-9.12l7.2-1.44v10.56h7.44v5.76h-7.44v11.58c0,4.44,0.18,5.34,0.9,6.12
		c0.54,0.54,1.2,0.72,2.4,0.72c1.5,0,3.12-0.24,4.14-0.66v5.7c-1.44,0.6-3.78,1.02-5.94,1.02c-6.24,0-8.7-2.82-8.7-9.6V43.19H439.86
		z"/>
	<path class="st0" d="M464.82,25.19h7.32v8.22h-7.32V25.19z M464.88,66.83v-29.4h7.2v29.4H464.88z"/>
	<path class="st0" d="M492.42,67.67c-9.36,0-15-5.88-15-15.48c0-9.48,6-15.66,15.24-15.66c9.36,0,15,5.88,15,15.54
		C507.66,61.55,501.66,67.67,492.42,67.67z M492.54,42.59c-4.74,0-7.5,3.84-7.5,9.54c0,5.64,2.76,9.54,7.5,9.54
		c4.74,0,7.5-3.9,7.5-9.54C500.04,46.43,497.28,42.59,492.54,42.59z"/>
	<path class="st0" d="M532.86,66.83v-15.6c0-3.18-0.06-4.62-0.78-6.12c-0.78-1.5-2.28-2.7-4.98-2.7c-1.92,0-3.6,0.72-4.5,1.68
		c-1.92,1.92-2.46,4.62-2.46,8.28v14.46h-7.2v-14.4c0-9.3-0.12-12.42-0.18-15h6.78l0.12,3.9h0.06c1.92-3.06,5.4-4.8,9.42-4.8
		c6.78,0,10.92,3.78,10.92,12.06v18.24H532.86z"/>
	<path class="st0" d="M139.86,89.41h29.52v6.18h-21.72v10.86h18.9v6h-18.9v11.64h22.74v6.24h-30.54V89.41z"/>
	<path class="st0" d="M196.62,141.85v-9.48c0-2.52,0.12-4.14,0.36-5.76h-0.06c-1.86,2.82-5.22,4.56-9.36,4.56
		c-8.46,0-13.08-6.54-13.08-15.42c0-9.54,5.46-15.72,13.68-15.72c4.2,0,7.2,1.74,9.06,4.5h0.06l0.24-3.6h6.72
		c-0.24,4.68-0.42,9.42-0.42,14.16v26.76H196.62z M196.8,115.09c0-5.4-2.82-9.18-7.38-9.18c-4.74,0-7.32,3.9-7.32,9.72
		c0,5.58,2.64,9.72,7.5,9.72c4.32,0,7.2-3.42,7.2-9.24V115.09z"/>
	<path class="st0" d="M230.88,130.33l-0.12-3.9h-0.06c-1.92,3.06-5.34,4.74-9.24,4.74c-6.66,0-10.8-3.72-10.8-12v-18.24h7.2v15.6
		c0,3.18,0.06,4.62,0.78,6.12c0.78,1.5,2.28,2.7,4.8,2.7c1.86,0,3.48-0.78,4.44-1.68c1.92-1.92,2.4-4.68,2.4-8.28v-14.46h7.2v14.88
		c0,8.82,0.12,12.06,0.18,14.52H230.88z"/>
	<path class="st0" d="M244.68,88.69H252v8.22h-7.32V88.69z M244.74,130.33v-29.4h7.2v29.4H244.74z"/>
	<path class="st0" d="M259.26,141.85v-26.28c0-4.68-0.06-9.9-0.18-14.64h6.78l0.12,3.9h0.06c1.86-2.94,5.22-4.8,9.54-4.8
		c8.4,0,13.08,6.6,13.08,15.48c0,9.48-5.52,15.66-13.74,15.66c-3.9,0-6.84-1.56-8.58-3.9h-0.06c0.12,1.08,0.18,2.82,0.18,5.1v9.48
		H259.26z M266.34,116.17c0,5.34,2.76,9.18,7.38,9.18c4.74,0,7.26-3.9,7.26-9.78c0-5.52-2.58-9.66-7.5-9.66
		c-4.26,0-7.14,3.36-7.14,9.24V116.17z"/>
	<path class="st0" d="M333.3,130.33v-15.6c0-3.18-0.06-4.62-0.84-6.12c-0.72-1.5-2.22-2.7-4.86-2.7c-1.8,0-3.48,0.72-4.38,1.68
		c-1.92,1.92-2.4,4.62-2.4,8.28v14.46h-7.2v-15.6c0-3.18-0.06-4.62-0.78-6.12c-0.78-1.5-2.28-2.7-4.86-2.7
		c-1.86,0-3.48,0.72-4.44,1.68c-1.92,1.92-2.4,4.62-2.4,8.28v14.46h-7.2v-14.4c0-9.3-0.12-12.42-0.18-15h6.78l0.12,3.9h0.06
		c1.92-3.06,5.34-4.8,9.3-4.8c4.56,0,7.8,1.86,9.48,5.28c2.52-3.66,5.88-5.28,10.2-5.28c6.6,0,10.8,3.78,10.8,12.06v18.24H333.3z"/>
	<path class="st0" d="M345.3,115.69c0-9.66,6-15.66,14.94-15.66c9.48,0,14.16,6.42,14.16,14.46c0,1.14-0.12,2.34-0.3,3.18h-21.12
		c0.48,5.28,3.72,7.86,8.34,7.86c3.72,0,6.96-1.26,9.42-3.9l3.12,4.56c-3.6,3.36-8.04,4.98-13.08,4.98
		C351.06,131.17,345.3,125.47,345.3,115.69z M366.78,112.69c-0.12-4.14-2.58-7.14-6.66-7.14c-4.08,0-6.48,2.76-7.02,7.14H366.78z"/>
	<path class="st0" d="M399.48,130.33v-15.6c0-3.18-0.06-4.62-0.78-6.12c-0.78-1.5-2.28-2.7-4.98-2.7c-1.92,0-3.6,0.72-4.5,1.68
		c-1.92,1.92-2.46,4.62-2.46,8.28v14.46h-7.2v-14.4c0-9.3-0.12-12.42-0.18-15h6.78l0.12,3.9h0.06c1.92-3.06,5.4-4.8,9.42-4.8
		c6.78,0,10.92,3.78,10.92,12.06v18.24H399.48z"/>
	<path class="st0" d="M409.92,106.69v-5.76h4.68v-9.12l7.2-1.44v10.56h7.44v5.76h-7.44v11.58c0,4.44,0.18,5.34,0.9,6.12
		c0.54,0.54,1.2,0.72,2.4,0.72c1.5,0,3.12-0.24,4.14-0.66v5.7c-1.44,0.6-3.78,1.02-5.94,1.02c-6.24,0-8.7-2.82-8.7-9.6v-14.88
		H409.92z"/>
</g>
	</svg>
);

export default Volvo;