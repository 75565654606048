import React from 'react';
import ImageTiles from 'components/ImageTiles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {
  Row,
  Col,
} from 'reactstrap';
import FbLoginButton from 'components/FbLoginButton';
import SponsorsBox from 'components/SponsorsBox';
import SponsorsBoxVertical from 'components/SponsorsBoxVertical';
import 'assets/css/main.css';
import findUser from 'requests/findUser';
import registerUser from 'requests/registerUser';
import giveawayDetails from 'requests/giveawayDetails';
import Spinner from 'components/Spinner';
import { toast } from 'react-toastify';

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginStatus: null,
      giveawayIsActive: false,
      isFetchingGiveawayStatus: true,
      showHelp: false,
      windowWidth: window.innerWidth
    };
  }

  componentDidMount(){
    this.fetchGiveawayDetails();
    var self = this;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referall = urlParams.get('refer');

    window.FB.getLoginStatus(function(response) {
      self.setState({ loginStatus: response?.status })
      if(response?.status === 'connected'){
        window.FB.api('/me?fields=id,name,email&access_token='+response?.authResponse?.accessToken, function(resp) {
          findUser(resp?.id).then(() => {
        }).catch(() => {
            registerUser(resp?.id, resp?.name, resp?.email, referall);
          })
        });
      }else{
        localStorage.removeItem('userID');
        localStorage.removeItem('loginStatus');
      }
    });
  }

  updateWidth = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidUpdate(prevProps) {
    window.addEventListener('resize', this.updateWidth)
    return () => window.removeEventListener('resize', this.updateWidth)
  }

  fetchGiveawayDetails = () => {
    giveawayDetails(1).then((resp) => {
      this.setState({giveawayIsActive: resp?.data?.Data?.isActive});
    }).catch((err) => {
      this.setState({giveawayIsActive: false});
    }).finally(() => {
      this.setState({isFetchingGiveawayStatus: false});
    })
  }

  handleLogin = () => {
    var self = this;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referall = urlParams.get('refer');

    window.FB.login(function(response) {
      localStorage.setItem('userID', response?.authResponse?.userID);
      localStorage.setItem('loginStatus', response?.status);

      window.FB.api('/me?fields=id,name,email&access_token='+response?.authResponse?.accessToken, function(resp) {
        findUser(resp?.id).then(() => {
        }).catch(() => {
          registerUser(resp?.id, resp?.name, resp?.email, referall)
        })
      });

      self.setState({ loginStatus: response?.status })

    }, {scope: 'public_profile,email'});
  }

  handleLogout = () => {
    var self = this;
    window.FB.logout(function(response) {

      localStorage.removeItem('userID');
      localStorage.removeItem('loginStatus');

      self.setState({ loginStatus: response?.status })
    });
  }

  toggleHelp = () => {
    this.setState({showHelp: !this.state.showHelp});
  }

  copyToClipboard = () => {
    navigator.clipboard.writeText('https://giveaway.montalegrecircuit.pt/').then(function() {
      toast.success('Endereço copiado com sucesso');
    }, function(err) {
      toast.error('Não foi possível copiar o endereço.');
    });
  }

  render(){
    const loginStatus = localStorage.getItem('loginStatus');
    const { giveawayIsActive, isFetchingGiveawayStatus } = this.state;
    if(isFetchingGiveawayStatus) return(
      <Spinner />
    );

    if (!giveawayIsActive) return (
      <>
        <Header loginStatus={loginStatus} handleLogout={this.handleLogout}/>
        <div id="giveaway-over-container">
          <p>Este passatempo já terminou :( <br />
          Se não ganhaste nenhum prémio sabes que podes sempre comprar o teu bilhete em <a href="https://montalegre.bol.pt/">montalegre.bol.pt</a></p>
        </div>
        <Footer/>
      </>
    );
    return(
      <Col sm="12" style={{ height: '100vh' }}>
        {this.state.showHelp &&
          <div className="login-help-wrapper-overlay" onClick={this.toggleHelp}>
            <div className="login-help-wrapper p-3" onClick={(e) => e.stopPropagation()}>
              <p className="login-help-close"><span onClick={this.toggleHelp} className="p-3">x</span></p>
              <h3>Não consigo iniciar sessão!</h3>
              <p className="login-help-text">
                Devido a recentes alterações à plataforma do Facebook, está temporariamente indisponível o login através de navegadores integrados.
              </p>
              <p>
                Para iniciar sessão basta copiares o endereço e abrir no teu navegador de preferência.
              </p>
              <p>https://giveaway.montalegrecircuit.pt/</p>
              <button onClick={this.copyToClipboard} className="login-help-clipboard">Copiar endereço</button>
            </div>
          </div>
        }

        <Row style={{height: '8%'}}>
          <Header loginStatus={loginStatus} handleLogout={this.handleLogout}/>
        </Row>

        <Row className="main-container align-items-center justify-content-center" >
          {this.state.windowWidth  > 768 && 
            <Col sm="4" className="h-100">
              <SponsorsBox />
            </Col>
          }
          {!isFetchingGiveawayStatus &&
            <Col sm="8" className="align-items-center justify-content-center">
              {loginStatus === 'connected'
                ?
                <div className="h-100 w-100 align-items-start justify-content-center">
                  <ImageTiles />
                </div>
                :
                <div className="fb-login-container align-items-center justify-content-center">
                  <span>Para jogar por favor faz login</span>
                  <div className="fb-login-button">
                    <FbLoginButton onClick={this.handleLogin}/>
                    <p className="login-help-toggle mt-2" onClick={this.toggleHelp}>Não consigo iniciar sessão!</p>
                  </div>
                </div>
              }
            </Col>
          }
          {this.state.windowWidth <= 768 && 
            <Col>
              <SponsorsBoxVertical />
            </Col>
          }
        </Row>

        <Row>
          <Footer />
        </Row>
      </Col>
    );
  }
}

export default Main;