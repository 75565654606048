const CiMontalegreLogo = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="37.5"
      viewBox="0 0 120 122"
      {...props}
    >
        <path fill="currentColor" d="M188.3453,48.1081c0,1.5578-.0365,3.117.0175,4.673.0223.6435-.163.7908-.7828.7646-8.4968-.3593-15.4293-3.8339-20.4905-10.66A26.1762,26.1762,0,0,1,183.9465,1.498C192.4758.1265,199.9512,2.567,206.2956,8.45c.4089.3792.401.5838.0042.97q-3.474,3.3844-6.8928,6.8255c-.3383.3393-.5215.3531-.8864.013a15.0257,15.0257,0,0,0-17.583-2.3228,15.2639,15.2639,0,0,0,4.796,28.5074,14.3621,14.3621,0,0,0,2.1137.1738c.4175.006.51.1457.5059.5336C188.3348,44.8034,188.3454,46.4558,188.3453,48.1081Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M212.2554,27.5551q0-12.3214-.0113-24.6429c-.0011-.53.0975-.7115.6785-.706,3.3751.0317,6.7508.0261,10.1261.004.5089-.0034.68.0958.6793.65q-.0248,24.6784.001,49.3568c.0006.5809-.212.634-.6951.6314-3.3517-.0185-6.7037-.0255-10.0553.005-.5838.0053-.7387-.134-.7372-.7264Q212.2725,39.8413,212.2554,27.5551Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M269.1833,27.5464q0-12.249-.0152-24.498c-.0017-.6184.136-.7857.7685-.7792,3.3746.0348,6.75.0269,10.1247.0047.49-.0032.6272.1121.6268.6165q-.0213,24.71.0021,49.4208c0,.5436-.1961.5908-.6472.5886-3.3985-.0168-6.7972-.0256-10.1955.0064-.594.0056-.6761-.1969-.6751-.72Q269.1957,39.8662,269.1833,27.5464Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M249.9987,53.57c-7.567-17.163-15.0769-34.1964-22.5843-51.2244.1692-.2064.3719-.12.5444-.12,3.6808-.0056,7.3617.0069,11.0423-.017a.8639.8639,0,0,1,.9353.6153q7.6985,17.3173,15.4312,34.6193a1.1377,1.1377,0,0,1,.0967.9331q-2.6664,7.419-5.3035,14.8484C250.13,53.3116,250.0825,53.3927,249.9987,53.57Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M263.7078,81.2383c.0014-5.3312.0134-10.6625-.01-15.9937-.0026-.6136.1355-.7935.7743-.79q7.6429.0427,15.2861,0c.6333-.0034.7816.168.7779.7871q-.0456,7.6428-.0111,15.2858c.0036.6647-.2188.7569-.8043.7547-5.3309-.02-10.662-.0088-15.9929-.0066l-.0392-.0033Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M263.6746,98.0137q8.1352-.008,16.2706-.0232c.42-.0012.5723.1074.5711.5527q-.0206,7.8528,0,15.7055c.0012.4779-.1656.5853-.62.5836-5.0935-.0184-10.1872-.0159-15.2808-.0187-.924-.0006-.9238,0-.9241-.8945l-.0057-15.9169Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M247.2271,98.02q-.0083,7.9237-.0166,15.8476c-.001.9565,0,.9479-.938.9474-5.0232-.0031-10.0465-.0139-15.07.0134-.6634.0036-.8137-.19-.81-.835.0264-5.33.013-10.6593.0117-15.989l-.0123.0125c.8468-.0063,1.6936-.0166,2.54-.0181q7.0256-.0128,14.051-.0218Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M230.25,81.172q-.0075-8.0617-.025-16.1236c-.0017-.4677.1345-.602.5971-.6q7.7789.0276,15.5579.018c.3718,0,.6146.0194.6126.5121q-.0336,8.1639-.0327,16.328l.0124-.013c-.2819-.011-.5639-.0314-.8459-.0316q-6.8176-.003-13.6353,0c-.6821.0005-1.3642.0241-2.0463.037Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M196.8258,98.05c-.282-.0128-.5639-.0363-.8459-.0368-4.9762-.0075-9.9523-.02-14.9284-.0057-.51.0015-.676-.0967-.6734-.6478q.0354-7.7473,0-15.4947c-.0025-.5471.1542-.6562.67-.6542,5.0234.0194,10.0469.0127,15.07.0095.2808,0,.5617-.0346.8426-.0532l.1966.1273q-.0078,8.3441-.0157,16.6882Q196.9837,98.0169,196.8258,98.05Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M180.2858,106.4749c0,2.5718-.0148,5.1437.0125,7.7151.0055.5242-.124.6782-.6632.6756q-7.68-.0363-15.3594-.0093c-.5889.0023-.7484-.1537-.7457-.7439q.0348-7.68.0007-15.3595c-.0024-.5745.1281-.7584.7328-.7552q7.6443.0413,15.2887.01c.5908-.0023.7543.1639.7471.7523C180.2678,101.3312,180.2858,103.9032,180.2858,106.4749Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M196.8258,98.05q.1579-.0336.3159-.0674c2.4768.0122,4.9536.03,7.43.0357,2.784.0063,5.568.01,8.3519-.0059.4628-.0026.6644.0891.6625.6181q-.0286,7.821-.0013,15.6421c.0015.4763-.1518.59-.6044.5889q-7.75-.0256-15.5-.004c-.6083.0026-.678-.2319-.6762-.7409Q196.832,106.0838,196.8258,98.05Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M197.1574,81.2948l-.1966-.1273q-.01-8.1012-.0263-16.2023c-.0013-.4137.1546-.5154.54-.5144q7.8537.0207,15.7075.0092c.5624-.0014.516.3058.5157.6752q-.0066,8.0661-.0042,16.1321l.0067-.01q-7.8482.0016-15.6963.0047C197.7217,81.2618,197.44,81.2833,197.1574,81.2948Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M213.7,81.2569l-.0067.01c.2574-.0148.5149-.0423.7725-.0425q7.5056-.0037,15.0112-.004c.2576,0,.5153-.0318.7728-.0488l.1948.1269c.0038,3.5751.0156,7.15.0083,10.7251-.0041,1.9977-.04,3.9953-.0612,5.993l.0123-.0125q-8.0948-.0042-16.1894-.0024c-.3626,0-.5476-.0428-.5459-.4856Q213.7018,89.3868,213.7,81.2569Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M247.2271,98.02q-.1219-.0215-.2439-.0428-.0054-8.342-.011-16.6842l-.0124.013a1.4645,1.4645,0,0,1,.3321-.0785q8.2077.0011,16.4156.0105l-.0189.0346.0392.0033q-.0061,8.1936-.0138,16.3878a3.058,3.058,0,0,1-.0394.35l.0111-.0115q-7.7707-.003-15.5414-.0051C247.8386,97.9971,247.5329,98.0121,247.2271,98.02Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M247.1267,120.1022c-.2344.391-.4632.7856-.7048,1.1722-.2632.4213-.5478.4095-.8082.001a6.1409,6.1409,0,0,0-.8348-1.1439q0,.52,0,1.04c0,.7065-.0031,1.4131.0027,2.1195.0021.244-.0623.4213-.3411.43-.2819.0087-.3521-.1584-.3513-.4094q.0077-2.2255.002-4.451c0-.1986.0093-.3861.2565-.4312a.51.51,0,0,1,.5739.3039c.3372.5863.6876,1.165,1.09,1.8437.3853-.6409.7267-1.1889,1.0462-1.7494.1524-.2673.3226-.4871.6594-.39.3545.1025.2313.42.2328.6575.009,1.3659.0021,2.7318.0063,4.0977.0008.2744.01.5428-.3764.5256-.3435-.0153-.3212-.2686-.3206-.51q.0034-1.5337.001-3.0675Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M206.6362,120.5162c0,.8524.007,1.705-.0043,2.5573-.0034.2527.1311.6239-.3484.6036-.4573-.0193-.3313-.38-.333-.636q-.013-2.0155-.0013-4.0312c.001-.2423-.0906-.5431.2924-.6149.3524-.066.5071.1365.6323.4217a21.7042,21.7042,0,0,0,1.4309,2.8371c0-.9-.0014-1.7992.0009-2.6988.0006-.2532-.0616-.5591.3429-.5579.39.0013.3567.2908.3565.5538-.0006,1.3909.0022,2.7819-.0024,4.1728-.0007.2213.0775.493-.2747.5528-.34.0577-.5091-.102-.64-.4033-.4057-.9362-.836-1.8618-1.2568-2.7915Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M192.1625,120.44c0,.9088-.0028,1.8177.0017,2.7265.0014.2765.0057.5332-.387.5135-.36-.0181-.3162-.2745-.3162-.5081q-.0006-2.1192.0008-4.2383c0-.23-.0522-.4907.2947-.5383a.505.505,0,0,1,.5933.364c.4086.9415.8429,1.8718,1.3,2.88.2568-.297.1543-.5834.16-.8343.0165-.6824.0061-1.3655.0059-2.0483,0-.2211.0883-.358.3291-.3633.2279-.0049.3673.1028.3681.3287q.0084,2.331.0017,4.662c-.0005.1983-.1288.28-.3212.2979a.4808.4808,0,0,1-.5515-.3225c-.3362-.7768-.6929-1.5446-1.0428-2.3154-.0955-.21-.1949-.4189-.2924-.6283Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M223.1673,120.4335c0,.8916.0034,1.7832-.0022,2.6748-.0016.2445.0852.5522-.3276.5715-.4061.0191-.37-.262-.37-.5264q.0018-2.1242.0011-4.2482c0-.209-.0409-.45.254-.5034a.4939.4939,0,0,1,.6176.3218,21.8206,21.8206,0,0,0,1.48,2.9281c0-.8685-.008-1.737.0049-2.6053.0038-.2542-.1322-.6254.322-.6461.4513-.0206.3689.3346.37.6052q.0061,2.0532-.0009,4.1066c-.0009.2189.09.4939-.2611.562-.3389.0658-.5146-.0914-.6459-.3921-.4209-.9645-.8619-1.92-1.2952-2.8793Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M255.28,121.551c0-.8455-.003-1.691.0018-2.5365.0014-.2473-.0775-.57.3185-.5845.44-.0163.3785.3276.379.6044q.0041,2.0532-.0009,4.1064c-.0007.2244.0721.4964-.2633.57-.34.0748-.5131-.0954-.6437-.3951a23.1545,23.1545,0,0,0-1.4482-2.885,7.1269,7.1269,0,0,1-.0156,1.3289c-.1259.6523.4262,1.43-.3491,1.9611-.7476-.5569-.2346-1.33-.3067-1.9918a25.0871,25.0871,0,0,1-.0238-2.7585c.0005-.23-.053-.4938.29-.5437a.51.51,0,0,1,.5994.3618c.4085.9376.8395,1.8654,1.2619,2.797Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M203.7173,121.1513c.4757.6613.9111,1.2414,1.3147,1.8428.12.1792.5044.387.1685.6251-.28.1985-.5586.0341-.7618-.2689-.38-.5663-.7955-1.1087-1.1966-1.6608-.0761-.1048-.1538-.2085-.36-.4872,0,.8016-.0037,1.3986.0017,1.9955.0022.2549-.0115.477-.3526.4832-.3533.0064-.348-.2248-.3474-.476.0035-1.4362.0146-2.8726-.0062-4.3086-.0061-.4237.17-.5342.5488-.5084.3046.0208.6118.003.9179.005.9583.0064,1.5853.4931,1.6525,1.2809C205.3628,120.4444,204.8014,121.0031,203.7173,121.1513Zm-.1893-.7385c.543.0293,1.07-.0183,1.0536-.6614-.016-.6352-.5583-.6039-1.0474-.655-.6171-.0644-.6438.2285-.6722.7227C202.8274,120.4219,203.1052,120.4865,203.528,120.4128Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M170.1216,121.1246c.4485.6287.8783,1.21,1.2822,1.81.122.1811.4881.39.1666.6225-.2864.2069-.5646.0216-.7645-.269-.36-.5227-.74-1.0311-1.114-1.5438-.079-.1082-.1681-.209-.3058-.3789a4.6228,4.6228,0,0,0-.1413,1.6913c-.0213.2467.0724.5546-.3373.5611-.3913.0061-.3658-.2716-.3654-.538.002-1.388.0187-2.7764-.0083-4.1639-.01-.5127.1919-.669.6682-.6238.3031.0287.6112,0,.9169.0064a1.4735,1.4735,0,0,1,1.5294,1.2653,1.3359,1.3359,0,0,1-1.1893,1.4864C170.3714,121.0716,170.2828,121.0891,170.1216,121.1246Zm-.24-.7575c.5583.0206,1.0762-.028,1.0631-.6748-.0135-.6616-.5657-.6007-1.0449-.6406-.5146-.0428-.6821.1-.6805.6481C169.22,120.268,169.3772,120.4863,169.8811,120.3671Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M275.505,121.1923c.4891.6794.9347,1.2811,1.3582,1.8979.1121.1634.4362.3588.101.5732a.4718.4718,0,0,1-.6727-.1852c-.3662-.52-.7422-1.0337-1.1159-1.5487a1.2371,1.2371,0,0,0-.5367-.4584c0,.5443.0075,1.0886-.0033,1.6326-.0052.26.1134.6244-.35.6138-.4006-.0093-.3232-.3285-.3238-.5768-.0032-1.3675.0187-2.7356-.0121-4.1025-.0117-.5188.1936-.6667.665-.62.2568.0255.5183.003.7776.0051,1.0055.0084,1.5958.4558,1.6627,1.2585C277.1225,120.49,276.6313,121.0028,275.505,121.1923Zm-.24-.7587c.18,0,.3463.0136.51-.0028a.597.597,0,0,0,.5867-.5958.6234.6234,0,0,0-.5753-.6841c-.1611-.0264-.3323.01-.4935-.0163-.5261-.0845-.6882.1114-.6739.6514C274.6318,120.2883,274.7086,120.5736,275.265,120.4336Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M269.8177,121.0484c.0231-.4325.0116-.8846.079-1.3246a1.5546,1.5546,0,0,1,1.2486-1.2933,1.515,1.515,0,0,1,1.476.479c.1376.1271.2984.2815.1053.474a.4017.4017,0,0,1-.56.07c-1.052-.62-1.63-.2778-1.64.9459-.0043.5424-.019,1.0858.0058,1.6271a.9453.9453,0,0,0,.949,1.0167c.6234-.0457.8913-.4416.9292-1.0408.0337-.5332.0586-1.0243-.7308-.8722-.2516.0484-.48-.0127-.4644-.3575a.3155.3155,0,0,1,.4393-.316c.4732.1567,1.2618-.4471,1.4137.3871.3,1.6484-.2791,2.7336-1.38,2.8945a1.6091,1.6091,0,0,1-1.795-1.334A12.9054,12.9054,0,0,1,269.8177,121.0484Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M234.7845,121.0364c0-.7065.0123-1.4133-.0052-2.1193-.0091-.3652.1291-.516.495-.4984.376.0181.7546-.0133,1.13.0107a1.6524,1.6524,0,0,1,1.63,1.6944q.0237.9531,0,1.9073a1.6493,1.6493,0,0,1-1.6366,1.6874c-1.613.1194-1.613.1194-1.613-1.4811Q234.7846,121.6368,234.7845,121.0364Zm.7.0623c0,.4948-.0019.99.0013,1.4844.0008.1371-.043.2968.0846.3967.4565.3575,1.6806-.1221,1.7344-.7292a14.1532,14.1532,0,0,0,.0051-2.3284c-.0461-.5988-.5774-.83-1.4348-.79-.708.0333-.3215.5483-.3742.8383A6.434,6.434,0,0,0,235.4849,121.0987Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M179.1708,120.4626c0,.5652.0348,1.1331-.008,1.6951a1.5931,1.5931,0,0,1-1.5892,1.4962,1.5631,1.5631,0,0,1-1.6086-1.4693c-.0609-1.1513-.0291-2.3076-.03-3.4617,0-.2247.0574-.4272.3513-.4254.3.0018.3341.2087.3346.4339.0023,1.0366-.01,2.0734.0046,3.11.01.7.3618,1.1006.9218,1.1079.5727.0073.9167-.3833.9439-1.0819.0092-.2352.0018-.4711.0018-.7067,0-.7775-.0027-1.555.0013-2.3324.0011-.233-.027-.5071.3147-.5268.3915-.0225.3758.2737.3779.5357.0043.5418.0013,1.0837.0013,1.6256Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M184.5174,120.9889c0-.4-.03-.8029.0061-1.2a1.6827,1.6827,0,0,1,1.5964-1.51,1.6616,1.6616,0,0,1,1.6165,1.4862,17.2875,17.2875,0,0,1-.0018,2.4691,1.6148,1.6148,0,0,1-3.2108-.0455C184.4873,121.7919,184.5175,121.389,184.5174,120.9889Zm2.5411-.0208c0-.3534.0242-.7089-.0056-1.06a.8672.8672,0,0,0-.9221-.8773.8491.8491,0,0,0-.9128.881c-.0285.7059-.0255,1.4143-.0021,2.12a.85.85,0,0,0,.8838.9076.8715.8715,0,0,0,.9555-.9118C187.0821,121.6768,187.06,121.3216,187.0585,120.9681Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M248.8187,121.0742c0-.376-.0236-.7537.0048-1.1275a1.6773,1.6773,0,0,1,1.616-1.5646,1.6453,1.6453,0,0,1,1.6072,1.4917,17.9869,17.9869,0,0,1,.001,2.3948,1.6048,1.6048,0,0,1-1.6053,1.4786,1.6312,1.6312,0,0,1-1.6191-1.5456C248.7952,121.8278,248.8186,121.45,248.8187,121.0742Zm.6976.0077c0,.354-.02.7093.0046,1.0616a.8721.8721,0,0,0,.9084.8943.8548.8548,0,0,0,.9271-.8709,19.9877,19.9877,0,0,0-.0022-2.1935.8438.8438,0,0,0-.9366-.8543.866.866,0,0,0-.897.9012C249.4963,120.3726,249.516,120.7279,249.5163,121.0819Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M218.3628,121.0391c0-.3763-.0192-.7538.0044-1.1286a1.722,1.722,0,0,1,1.545-1.5772,1.6759,1.6759,0,0,1,1.6613,1.4416,15.9708,15.9708,0,0,1,.0024,2.5356,1.6015,1.6015,0,0,1-1.6675,1.41,1.6369,1.6369,0,0,1-1.5423-1.5532C218.3442,121.7927,218.3624,121.4154,218.3628,121.0391Zm2.5443.017h-.004a9.95,9.95,0,0,0-.0084-1.13.8508.8508,0,0,0-.8884-.8319.8387.8387,0,0,0-.9365.8494c-.0374.7286-.0329,1.4615-.0031,2.1908a.85.85,0,0,0,.9226.8719.8713.8713,0,0,0,.9126-.89C220.93,121.765,220.9071,121.41,220.9071,121.0561Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M266.4092,121.0287c0-.6592.0273-1.32-.0089-1.9772-.0281-.51.1772-.6632.6589-.6362.5633.0316,1.13.011,1.6947.0064.277-.0022.4554.083.4445.4008-.01.3044-.2132.3195-.44.3171-.306-.0032-.6121-.0017-.9182-.0006a.76.76,0,0,0-.7226,1.142c.0766.1692.2138.1548.3475.16.188.0079.3766.0036.5649.0025.2513-.0015.4772.0165.4764.3606-.0008.3654-.26.3-.49.3379-.3.05-.7728-.2135-.868.1877a4.9572,4.9572,0,0,0-.0038,1.5435c.0158.18.27.1329.4265.1353.4.0063.8.002,1.2006.003.2286.0005.4248.033.4272.3351.0022.2783-.1465.3736-.3968.3736-.6592,0-1.3184-.0027-1.9776.0028-.3555.003-.4225-.2045-.4187-.5053C266.4156,122.4885,266.4093,121.7586,266.4092,121.0287Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M198.7187,121.0406c0-.7064.014-1.4132-.0055-2.1191-.0109-.3927.1262-.5529.5316-.5355.5876.0252,1.1771.0106,1.7658.0046.26-.0026.4744.03.4867.3588.013.3458-.2154.365-.4693.3624-.3061-.003-.6153.0265-.9177-.0068-.5246-.0579-.7276.0867-.7216.6742.0057.5485.17.7168.6788.6413a2.2671,2.2671,0,0,1,.4233,0c.2412.01.3138.1548.31.3775a.2731.2731,0,0,1-.2974.31,4.2389,4.2389,0,0,1-.5647-.0012c-.37-.04-.5323.0507-.5374.4837-.0159,1.3723-.0375,1.3721,1.3406,1.3783.1412.0007.2827-.0026.4237.004a.314.314,0,0,1,.3321.3643.3045.3045,0,0,1-.3471.343c-.6829-.0037-1.3659-.01-2.0486.0019-.3946.0066-.3859-.251-.384-.5219.0025-.3532.0009-.7065.001-1.06Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M238.8957,121.027c0-.7081.0089-1.4164-.004-2.1242-.0058-.3194.0951-.4883.44-.483.6371.01,1.2745.0077,1.9117.0005.2527-.0029.4124.0836.4238.3531.014.3337-.2006.3771-.4663.3678-.2593-.009-.5192-.0025-.7788-.0024-.877,0-.8536,0-.8387.8857.006.3581.1265.4558.4511.4233.1869-.0187.3773-.002.5661-.002a.3292.3292,0,0,1,.375.3962c-.0165.2471-.1818.2766-.3925.2984-.99.1028-.9532.111-1.0024,1.1461-.0275.5775.1111.8223.72.733a7.2758,7.2758,0,0,1,.99-.0082c.2194-.0016.3689.0766.3771.3181a.3329.3329,0,0,1-.3791.3937c-.6609-.0033-1.3218-.007-1.9826.001-.3606.0043-.4195-.1993-.4145-.5021C238.9038,122.4905,238.8957,121.7587,238.8957,121.027Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M259.3937,123.189c.3415-1.413.706-2.9227,1.072-4.4321a.4176.4176,0,0,1,.4643-.339.4126.4126,0,0,1,.4479.359c.3709,1.508.7376,3.0171,1.1113,4.5244.0563.2272.0037.3732-.2379.41-.2054.0313-.3758-.0168-.4349-.25-.0809-.3194-.1838-.6351-.2383-.959-.0654-.3894-.1716-.6126-.662-.6147-.5031-.002-.584.2509-.6536.6268-.06.3235-.1557.64-.24.9592-.0618.2352-.243.27-.4434.235C259.33,123.665,259.2986,123.4955,259.3937,123.189Zm1.53-3.3382c-.1187.492-.1731.7885-.2642,1.0733-.0767.24.0264.2894.229.2933.2136.0042.3764-.0125.2878-.3063C261.0914,120.6287,261.0379,120.3368,260.924,119.8508Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M226.1473,123.2212c.3645-1.4776.74-2.9847,1.1048-4.4947a.3948.3948,0,0,1,.4516-.3386.4.4,0,0,1,.4453.3515c.3645,1.51.7385,3.0181,1.1049,4.5279.0391.1609.07.3452-.1615.3939-.2192.0463-.4353.0421-.51-.2324a9.0586,9.0586,0,0,1-.2528-1.0276c-.0578-.39-.2084-.5494-.6367-.5559-.4419-.0068-.57.189-.6375.5582-.0632.3463-.1652.6857-.2531,1.0274-.06.2345-.24.2685-.4417.2449C226.0847,123.6435,226.1162,123.4456,226.1473,123.2212Zm1.4945-3.19c-.0731.2961-.1329.5609-.2048.8224-.0678.2466.0132.3516.272.342.2048-.0075.3411-.0827.2553-.295C227.8537,120.6269,227.9291,120.2853,227.6418,120.0307Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M277.7288,121.08c0-.7073.015-1.4149-.0058-2.1216-.0117-.3942.1289-.5555.5307-.5411.5886.0212,1.1787.0131,1.7678.0021.26-.0049.3849.0665.383.3567-.0019.2687-.0763.3868-.36.3674-.2816-.0193-.5712.0316-.8473-.0117-.6632-.1041-.8416.1739-.7948.8039.0289.3895.1105.5627.52.5145a2.93,2.93,0,0,1,.6346.0118c.2.0206.2648.1654.2589.3541-.0061.1948-.0883.3117-.295.316a.413.413,0,0,0-.07.003c-.3318.0657-.8458-.1968-.9674.1422a4.6825,4.6825,0,0,0-.06,1.4677c-.003.2833.2071.2646.3954.2657.4007.0022.8018.0087,1.2022-.0026.2553-.0073.3862.058.3844.352-.0017.28-.1052.3709-.3725.3661-.6127-.0108-1.2261-.0141-1.8387.0006-.3841.0092-.4779-.18-.47-.5252C277.74,122.4945,277.7287,121.787,277.7288,121.08Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M212.7441,123.2757c.0442.2508-.0113.38-.2294.4-.2039.0185-.3769-.0066-.4364-.2476-.0959-.3887-.2215-.7717-.2908-1.1649-.0662-.3758-.2922-.4121-.6075-.4069-.31.005-.5488.0231-.6083.4109-.057.3711-.1874.73-.2612,1.1-.0549.275-.2248.34-.4619.3081-.3234-.044-.2605-.2611-.207-.4788q.5391-2.1969,1.07-4.3956c.0539-.2243.1225-.4053.3961-.4088.22-.0029.4287-.0045.4972.2743C211.9876,120.2212,212.3733,121.7759,212.7441,123.2757Zm-1.5143-3.2649c-.2455.2569-.1986.585-.3113.8539-.0474.1132-.0737.2555.0558.3a.6369.6369,0,0,0,.4056-.0038c.1266-.0483.0813-.1955.0539-.3086Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M172.3179,120.9761a12.6611,12.6611,0,0,1,.0078-1.272,1.6252,1.6252,0,0,1,1.1845-1.3682,1.4354,1.4354,0,0,1,1.5383.46c.1423.138.3595.2958.1056.52-.1726.1523-.356.2237-.5537.0189a.87.87,0,0,0-1.093-.2066,1.0255,1.0255,0,0,0-.5222,1.0724c.0127.5657.0075,1.132.002,1.6979a.9536.9536,0,0,0,.5036.94.8253.8253,0,0,0,1.04-.1386c.2408-.24.4582-.2473.6543-.0035.2271.2824-.07.4347-.2268.5643a1.6087,1.6087,0,0,1-2.6353-1.0119,12.0409,12.0409,0,0,1-.0084-1.2721Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M163.2963,120.9931c0-.4-.0265-.8032.0056-1.2011a1.6579,1.6579,0,0,1,1.1824-1.4494,1.3935,1.3935,0,0,1,1.4891.3907c.1507.1378.4441.2908.1829.5685a.3714.3714,0,0,1-.6066-.0034.84.84,0,0,0-1.037-.176.978.978,0,0,0-.5376.9909c.0112.6125-.0178,1.2263.0081,1.8379a.9062.9062,0,0,0,1.5245.7562c.2552-.1869.4564-.278.6567-.0264.249.3128-.0794.4737-.2578.6136a1.5116,1.5116,0,0,1-1.5935.2346,1.5327,1.5327,0,0,1-1.0094-1.3352C163.2639,121.7975,163.2963,121.3937,163.2963,120.9931Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M213.14,120.9821a10.0068,10.0068,0,0,1,.0085-1.2016,1.6222,1.6222,0,0,1,1.2529-1.3816,1.4683,1.4683,0,0,1,1.4764.4549c.1476.1308.3619.2986.1108.5215-.1656.1471-.3467.2456-.55.0246a.87.87,0,0,0-1.0932-.216,1.0135,1.0135,0,0,0-.53,1.0681c.015.59.0062,1.18.0042,1.77a.92.92,0,0,0,.559.9046.8159.8159,0,0,0,.98-.1615c.2377-.2438.4467-.26.65-.0232.2385.2774-.05.433-.2086.5691a1.4966,1.4966,0,0,1-1.5832.3089,1.5414,1.5414,0,0,1-1.0776-1.3641c-.036-.4215-.0067-.8486-.0067-1.2732Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M182.4194,121.2749c0-.3769-.021-.7552.0048-1.13.0462-.6718.08-1.2974-.9194-1.0913-.2158.0445-.3566-.0992-.3564-.332s.0677-.4218.3528-.422c.848-.0007,1.696.0008,2.5441-.0028.29-.0013.3505.18.3559.4162a.31.31,0,0,1-.4114.3438c-.8838-.1728-.9084.3372-.8811.9987.0418,1.0113.01,2.0255.0124,3.0384.0008.2678.0287.5459-.371.5254-.3406-.0174-.3325-.262-.3316-.5065C182.4208,122.5,182.4193,121.8874,182.4194,121.2749Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M196.2031,121.3818c0-.4-.0293-.8019.0065-1.1983.0634-.7014.0369-1.28-.9345-1.0781-.2217.046-.3611-.09-.3663-.3173a.3383.3383,0,0,1,.3788-.3959c.8463.0035,1.6926.0022,2.5389.0009.24,0,.329.1326.3377.3542.0107.2723-.1236.41-.3835.36-.8378-.16-.95.2635-.9065.9747.06.9837.0228,1.9739.01,2.9611-.0033.2478.1489.6169-.3209.6332-.4677.0162-.3526-.3417-.3569-.6022C196.1965,122.51,196.203,121.946,196.2031,121.3818Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M257.6659,121.3893c0-.5421-.03-1.0864.009-1.6257.0352-.4853-.1109-.6851-.6195-.6354-.2679.0261-.6888.15-.6684-.3574.0195-.4854.4256-.3371.7028-.3433.66-.0149,1.32-.0049,1.98-.0048.2589,0,.5448-.0457.5625.3475.018.4017-.266.4061-.5256.3629-.6477-.1079-.7915.1727-.7638.7779.05,1.0819.0138,2.1678.0142,3.252,0,.2593.0479.5577-.3486.5553s-.3417-.3054-.3422-.5612Q257.6645,122.2732,257.6659,121.3893Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M229.8754,121.0005c0-.7062.0021-1.4125-.001-2.1188-.0011-.2518.011-.4841.3509-.488.3515-.004.3436.2394.3431.4858q-.0033,1.3773-.0011,2.7545c0,1.3313,0,1.3313,1.3,1.3347.1412,0,.2825,0,.4237.0021.22.0033.3549.0983.3615.3354.0074.2681-.1209.3784-.387.3755-.6591-.0073-1.3186-.0125-1.9774.0022-.3686.0083-.42-.196-.416-.4939C229.8815,122.46,229.8753,121.73,229.8754,121.0005Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M263.1106,121.0458c0-.6587-.0082-1.3176.0043-1.9761.0048-.256-.126-.6265.3254-.6386.4413-.0118.3614.3427.3624.6128.0042,1.1527.0168,2.3058-.0068,3.4581-.0081.394.1005.55.5087.5163.3973-.0322.7994-.0037,1.1993-.0073.2212-.002.3651.0791.3745.3179a.3324.3324,0,0,1-.378.3931c-.6587-.0017-1.3176-.0074-1.9761.0028-.3559.0055-.424-.1835-.4182-.4912C263.12,122.5046,263.1106,121.7751,263.1106,121.0458Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M180.73,120.992c0,.728-.0015,1.456.001,2.1841.0008.2246-.0073.433-.3107.4433-.3338.0112-.3707-.2-.37-.4632.0041-1.456.0007-2.9121.0036-4.3682,0-.24.0059-.4954.3512-.4884.354.0072.3218.2768.3232.5083C180.733,119.5359,180.73,120.2639,180.73,120.992Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M190.5768,121.1c0,.7075-.0039,1.4151.0021,2.1226.0023.2691-.0492.465-.3805.4588-.3083-.0057-.32-.2035-.3194-.432q.0036-2.1933-.0006-4.3867c-.0008-.261.0249-.4859.3646-.4666.3354.0191.3352.2637.3345.51C190.5753,119.6372,190.5767,120.3684,190.5768,121.1Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M167.6572,120.9765c0,.73-.0048,1.4594.0026,2.189.0028.2722-.0543.4674-.3816.4519-.2924-.0139-.3227-.2068-.3222-.44q.0048-2.1891.0018-4.3781c0-.2455.0057-.495.3438-.4992.3355-.0043.3561.2363.3558.4872Q167.6559,119.882,167.6572,120.9765Z" transform="translate(-161.9398 -1.1257)"/>
        <path fill="currentColor" d="M216.8,121.0436c0-.7317.0024-1.4634-.0014-2.1951-.0011-.2284.0133-.4362.3112-.4513.3193-.0162.3942.171.3933.45q-.0075,2.1951.001,4.39c.0013.2819-.0707.4564-.3934.4435-.3076-.0122-.3133-.2148-.3121-.4417C216.8022,122.507,216.8,121.7753,216.8,121.0436Z" transform="translate(-161.9398 -1.1257)"/>
    </svg>
  );

  export default CiMontalegreLogo;