const Securnet = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100" 
    height="100" 
    viewBox="0 0 1024 168"
    fill="currentColor"
    {...props}
  >
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M86.539368,1.000000 
	C87.873558,1.987673 88.557045,3.349228 89.650192,3.904954 
	C100.122108,9.228569 110.713821,14.317183 121.171417,19.668163 
	C133.626404,26.041174 145.979675,32.612877 158.389145,39.075211 
	C159.489563,39.648266 160.679382,40.049637 161.765579,40.504723 
	C161.765579,48.326824 161.765579,56.104774 161.765579,64.099937 
	C142.891876,60.129814 124.309097,56.106125 105.672050,52.351849 
	C97.117294,50.628567 88.476875,48.466267 79.836967,48.279842 
	C71.653847,48.103271 63.417782,50.230465 55.210106,51.380932 
	C45.385292,52.758068 35.565895,54.173859 25.327393,55.632271 
	C25.327393,45.841965 25.327393,36.755318 25.327393,27.334913 
	C40.701126,19.652308 56.373459,11.834407 72.024078,3.973270 
	C73.285843,3.339501 74.398399,2.408683 75.290146,1.307951 
	C78.692909,1.000000 82.385818,1.000000 86.539368,1.000000 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M73.458069,169.000000 
	C71.977211,167.998001 71.124763,166.654465 69.905525,166.046387 
	C56.390114,159.305679 42.888412,152.528000 29.208387,146.133926 
	C26.026991,144.646957 24.768011,143.138184 24.914480,139.615158 
	C25.204212,132.646317 25.000002,125.656952 25.000002,117.798981 
	C30.642277,118.286751 36.102848,118.635193 41.531757,119.259102 
	C48.470325,120.056503 55.385849,121.057968 62.306511,122.006424 
	C66.805664,122.623016 71.313469,123.928207 75.786690,123.805504 
	C82.429131,123.623299 89.073280,122.643089 95.664879,121.656357 
	C107.345612,119.907799 118.997887,117.960693 130.641891,115.978096 
	C138.754669,114.596756 146.828827,112.989876 154.931229,111.545387 
	C157.019760,111.173050 159.149750,111.033188 161.987823,110.701584 
	C161.987823,117.281700 162.259155,123.542923 161.770172,129.744202 
	C161.666214,131.062561 158.942413,132.483917 157.170746,133.313507 
	C138.733627,141.946686 120.216972,150.410141 101.784874,159.053925 
	C95.571548,161.967682 89.536079,165.260681 83.709663,168.690186 
	C80.638710,169.000000 77.277428,169.000000 73.458069,169.000000 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M1.000000,76.468399 
	C7.872155,74.912994 14.772038,73.975502 21.610565,72.707184 
	C34.934563,70.236031 48.225109,67.584335 61.527584,64.997505 
	C66.593201,64.012428 71.646896,62.237755 76.717911,62.193806 
	C83.007812,62.139297 89.341873,63.307896 95.599922,64.305397 
	C106.613602,66.060921 117.584076,68.087471 128.572647,70.000526 
	C131.129578,70.445663 133.686523,70.890793 136.652191,71.407074 
	C136.652191,81.911346 136.652191,92.759628 136.652191,103.997360 
	C117.994370,103.997360 99.373444,104.494926 80.794006,103.831245 
	C67.273216,103.348259 53.804577,101.357574 40.317516,99.991417 
	C27.500689,98.693146 14.690132,97.332962 1.438409,96.000000 
	C1.000000,89.645599 1.000000,83.291206 1.000000,76.468399 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M1025.000000,55.533882 
	C1013.148010,56.000000 1001.296021,56.000000 989.000000,56.000000 
	C989.000000,68.613411 989.000000,79.887367 989.000000,91.161324 
	C989.000000,101.654701 989.010254,112.148102 988.996155,122.641457 
	C988.987610,129.042404 987.134338,130.955124 980.974670,130.999985 
	C974.106262,131.050018 971.028259,128.511765 971.016541,122.519066 
	C970.974365,100.883156 971.000000,79.247108 971.000000,57.000000 
	C961.409180,57.000000 951.987366,57.005325 942.565552,56.998020 
	C936.838013,56.993580 934.042603,54.122860 934.000061,48.230690 
	C933.955322,42.045952 936.759399,39.017086 942.725342,39.010872 
	C967.543030,38.985023 992.361206,38.929386 1017.177490,39.113018 
	C1019.568420,39.130711 1021.947632,40.727501 1024.666138,41.295624 
	C1025.000000,45.689255 1025.000000,50.378513 1025.000000,55.533882 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M721.186035,42.664234 
	C730.490784,48.450451 735.151978,59.416904 732.963989,70.008995 
	C731.006104,79.487228 722.408630,87.926071 713.131653,89.293266 
	C717.995056,98.963440 722.839111,108.566727 727.655640,118.183823 
	C730.247925,123.359604 729.125488,128.271576 724.866760,130.615768 
	C721.011414,132.737900 713.265320,130.494293 711.167908,126.584869 
	C705.770569,116.524391 700.419800,106.438942 695.031921,96.373360 
	C692.754700,92.118927 688.781494,90.248756 684.340820,90.092239 
	C675.518799,89.781281 666.677368,89.929634 657.845520,90.028946 
	C653.209900,90.081070 651.069946,92.314255 651.022644,97.047836 
	C650.940918,105.213333 651.009399,113.380249 650.997131,121.546539 
	C650.986816,128.394852 648.373657,131.013855 641.608093,131.000000 
	C635.455750,130.987396 633.000916,128.391418 633.000549,121.885048 
	C632.999146,97.052788 632.996460,72.220528 633.002808,47.388268 
	C633.004211,41.981335 635.908569,39.025211 641.454529,39.015900 
	C664.120178,38.977825 686.788025,38.843170 709.449524,39.152374 
	C713.285583,39.204716 717.094360,41.262573 721.186035,42.664234 
M703.331055,57.000000 
	C688.352844,57.000008 673.374573,57.023308 658.396484,56.985714 
	C653.948914,56.974552 651.306946,58.856674 651.001282,63.477497 
	C650.703186,67.983772 653.483215,71.875885 657.810547,71.923447 
	C674.284607,72.104485 690.766113,72.195114 707.234375,71.838524 
	C710.811890,71.761055 714.540283,69.809784 714.968018,65.214951 
	C715.335999,61.262028 713.139954,58.643135 709.697876,57.316692 
	C708.089417,56.696869 706.096558,57.074478 703.331055,57.000000 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M318.872131,75.986389 
	C331.163666,83.395180 334.853546,94.138580 332.746552,107.609787 
	C330.959595,119.034966 324.058777,126.804634 310.871277,129.946075 
	C307.896332,130.654755 304.756836,130.940491 301.690857,130.955231 
	C283.039276,131.044937 264.387024,131.003601 245.735016,130.998169 
	C238.660553,130.996109 236.993713,129.241409 237.000000,121.854218 
	C237.005997,114.806007 238.724487,113.006035 245.526962,113.002975 
	C264.512054,112.994446 283.497284,113.037857 302.482178,112.980919 
	C309.841187,112.958847 315.881805,106.458733 314.996948,99.736366 
	C314.339172,94.739143 307.904205,90.037247 301.441833,90.015968 
	C286.287262,89.966072 271.122070,89.659615 255.981216,90.118668 
	C245.743546,90.429054 233.457489,78.247047 232.882507,67.314743 
	C231.992065,50.384373 243.626816,38.567684 261.078339,38.913582 
	C280.222443,39.293034 299.379944,39.027332 318.531433,38.975941 
	C322.173309,38.966167 325.510010,39.407619 326.961365,43.319183 
	C328.570648,47.656281 329.115784,52.075554 325.003265,55.360676 
	C323.651703,56.440331 321.474274,56.922459 319.670349,56.932476 
	C300.186218,57.040680 280.701111,56.959957 261.216919,57.059593 
	C259.183685,57.069992 256.415649,57.249485 255.292267,58.511253 
	C253.486038,60.539963 251.789032,63.545883 251.811874,66.123260 
	C251.849030,70.314079 255.153748,72.019249 259.275848,72.011337 
	C274.264008,71.982559 289.262604,71.687492 304.234528,72.181381 
	C309.021973,72.339310 313.738953,74.633209 318.872131,75.986389 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M897.999817,90.000000 
	C885.862793,90.000000 874.225708,90.000000 861.620728,90.000000 
	C862.088013,95.552406 862.499634,100.861267 862.990967,106.162735 
	C863.470276,111.334732 867.067810,112.929146 871.506592,112.960136 
	C886.493530,113.064743 901.481689,112.995323 916.469360,113.002342 
	C922.297729,113.005074 924.925476,115.885811 925.000061,122.313240 
	C925.063171,127.750076 922.103638,130.963425 916.732727,130.980698 
	C900.246460,131.033646 883.752869,131.293930 867.275635,130.904037 
	C854.802795,130.608902 845.014526,120.160019 845.003296,107.977402 
	C844.989563,92.989723 845.286926,77.994865 844.912964,63.016514 
	C844.589417,50.056343 855.447327,39.389664 868.299500,39.090546 
	C884.444092,38.714790 900.604309,38.961407 916.757507,39.021065 
	C922.380554,39.041832 925.079773,42.337257 924.999939,48.722519 
	C924.935913,53.844990 921.970459,56.955719 916.822998,56.979008 
	C903.167847,57.040787 889.494385,57.425659 875.862854,56.853970 
	C866.394043,56.456856 861.881775,58.817120 862.074829,71.759979 
	C863.442200,71.838448 864.849670,71.988083 866.257263,71.989479 
	C882.743713,72.005875 899.230164,71.994339 915.716614,72.003342 
	C920.871643,72.006157 923.867432,74.935577 923.999939,80.049652 
	C924.165649,86.446396 921.470337,89.879578 915.984985,89.976768 
	C910.158264,90.079994 904.328308,89.999985 897.999817,90.000000 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M749.170288,42.664032 
	C771.617310,60.033298 793.810486,77.117157 816.639282,94.690277 
	C816.801514,92.709206 816.985474,91.497597 816.987122,90.285736 
	C817.007080,75.795387 816.987122,61.304985 817.007263,46.814644 
	C817.013855,42.090080 820.041931,39.178268 825.020691,39.000050 
	C830.955627,38.787605 834.905457,41.251076 834.928955,45.977238 
	C835.069336,74.244057 834.998535,102.511925 834.998535,132.599991 
	C809.953613,113.305107 786.015686,94.863052 761.000000,75.590683 
	C761.000000,89.095299 761.000061,101.011398 761.000000,112.927498 
	C761.000000,116.758286 761.098022,120.592300 760.972351,124.418961 
	C760.845581,128.276474 757.412903,130.970963 752.864380,131.000000 
	C746.911560,131.038010 743.057373,128.736130 743.044983,124.649506 
	C742.960083,96.514610 743.000366,68.379333 743.000366,39.190296 
	C745.403503,40.485413 747.159973,41.432018 749.170288,42.664032 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M511.792175,57.000000 
	C499.822601,57.000000 488.325165,57.065483 476.828766,56.983765 
	C461.769928,56.876720 447.361145,71.055038 447.999176,85.830292 
	C448.499115,97.408478 457.491364,109.641602 470.920471,111.809586 
	C479.320129,113.165611 488.017151,112.746407 496.584717,112.971809 
	C502.246002,113.120758 507.914368,112.991356 513.579529,113.002365 
	C519.519531,113.013916 523.157349,117.600609 521.862305,123.424370 
	C520.481506,129.633728 518.721741,130.997086 512.050781,130.998978 
	C499.720673,131.002502 487.389618,131.099823 475.060699,130.978577 
	C451.625458,130.748154 429.829529,111.515892 429.833801,85.849182 
	C429.837463,63.822487 442.924530,46.359913 464.224731,40.397850 
	C467.792542,39.399200 471.646881,39.109123 475.376251,39.060558 
	C487.704224,38.900005 500.035645,38.994896 512.365723,39.002041 
	C519.045105,39.005913 521.853577,41.599182 521.997253,47.861095 
	C522.140747,54.116211 519.073242,56.996597 511.792175,57.000000 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M531.000000,78.947159 
	C531.000000,68.623146 530.955627,58.791393 531.023254,48.960407 
	C531.048157,45.338554 531.130798,41.068367 535.363953,40.111420 
	C538.930115,39.305248 542.995667,39.657173 546.576965,40.551067 
	C547.796814,40.855545 548.852417,44.357464 548.893860,46.420223 
	C549.117737,57.580261 549.148499,68.749596 548.964905,79.911102 
	C548.694458,96.356239 557.528442,109.420387 574.393127,112.677887 
	C580.896240,113.934006 587.023682,111.723686 592.245911,108.083900 
	C601.054199,101.944679 605.396423,93.384125 605.074768,82.362114 
	C604.739502,70.874107 604.978516,59.368645 605.008728,47.870926 
	C605.024719,41.780983 607.935242,38.980804 614.091675,39.000000 
	C620.223022,39.019123 622.997681,41.797253 622.999084,47.932793 
	C623.002014,60.430401 622.968323,72.928108 623.007202,85.425591 
	C623.070312,105.690460 608.242249,126.393883 586.085815,130.137405 
	C573.778198,132.216858 562.705566,130.159470 552.147034,123.700897 
	C539.541504,115.990112 533.209412,104.670494 531.993713,90.186569 
	C531.692871,86.601730 531.332886,83.021866 531.000000,78.947159 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M366.000885,131.000000 
	C360.509369,131.000000 355.517303,131.035828 350.526001,130.989868 
	C345.580322,130.944321 342.946838,127.655258 343.000000,121.705124 
	C343.048981,116.222641 345.800842,113.015968 350.616486,113.010574 
	C372.247284,112.986328 393.878143,112.976807 415.508911,113.015793 
	C421.160980,113.025978 423.013184,115.434105 422.999969,122.379227 
	C422.989471,127.907608 420.344208,130.979187 415.419586,130.988434 
	C399.113342,131.019058 382.806976,130.999985 366.000885,131.000000 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M404.690155,90.000000 
	C386.936951,90.000000 369.651093,89.957817 352.365723,90.032181 
	C348.458374,90.048988 345.323608,89.195107 343.681641,85.204247 
	C341.960907,81.021919 342.963562,77.117104 345.208191,73.663445 
	C345.938080,72.540367 348.366821,72.077538 350.022858,72.068184 
	C368.970032,71.961113 387.917999,71.996078 406.865784,72.003258 
	C410.841827,72.004768 412.870819,74.050385 412.993378,77.997452 
	C413.034607,79.325836 413.009003,80.656654 412.998413,81.986237 
	C412.950500,88.006088 411.327637,89.662354 404.690155,90.000000 
z"/>
<path styles="fill:#fff" opacity="1.000000" stroke="none" 
	d="
M369.000244,39.000000 
	C378.318817,39.000000 387.137512,38.978542 395.956055,39.006870 
	C402.856384,39.029034 404.045227,40.638828 403.999969,49.705406 
	C403.971497,55.407364 402.269012,56.997219 396.157623,56.998764 
	C381.182587,57.002552 366.207581,57.007187 351.232544,56.996159 
	C345.889526,56.992226 343.071930,54.032814 342.999969,48.411880 
	C342.921692,42.292973 345.559082,39.117008 351.029816,39.022629 
	C356.851685,38.922195 362.676666,39.000011 369.000244,39.000000 
z"/>

  </svg>
);

export default Securnet;