import React from 'react';
import 'assets/css/sponsors-box-vertical.css';
import PRacingFuelsLogo from 'components/icons/P1RacingFuelsLogo';
import CooperTiresLogo from 'components/icons/CooperTiresLogo';
import LusorecursosLogo from 'components/icons/LusorecursosLogo';
import CMMontalegreLogo from 'components/icons/CMMontalegreLogo';
import CAVRLogo from 'components/icons/CAVRLogo';
import FPAKLogo from 'components/icons/FPAKLogo';
import CiMontalegreLogo from 'components/icons/CiMontalegreLogo';
import VolvoLogo from 'components/icons/VolvoLogo';
import KreiselLogo from 'components/icons/KreiselLogo';
import VinhosLogo from 'components/icons/VinhosLogo';
import BelasVistasLogo from 'components/icons/BelasVistasLogo';
import McoutinhoLogo from 'components/icons/McoutinhoLogo';
import AscendumLogo from 'components/icons/AscendumLogo';
import SecurnetLogo from 'components/icons/SecurnetLogo';
import { Row, Col } from 'reactstrap'

const SponsorsBoxVertical = () => {
  return (
    <div className="h-100 w-100 pt-2 d-flex flex-column justify-content-between" style={{ paddingBottom: '15%' }}>
      <Row className="px-5 py-2">
        <Col xs="12" className="d-flex flex-column justify-content-center">
          <div className="title-sponsors-sidebar-box-title skew">
            <span>Title Sponsor</span>
          </div>
        </Col>
        <Col xs="12" className="d-flex flex-column justify-content-center py-4">
          <div className="d-flex justify-content-center sponsors-grid-row">
            <a href={process.env.REACT_APP_CM_MONTALEGRE_URL} rel="noreferrer" target="_blank">
              <CMMontalegreLogo className="sidebar-cm-montalegre" />
            </a>
          </div>
        </Col>
      </Row>
      <Row className="px-5 py-2">
        <Col xs="12" className="d-flex flex-column justify-content-center pb-4">
          <div className="title-sponsors-sidebar-box-title skew">
            <span>Sponsors</span>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            {/* <a href={process.env.REACT_APP_COOPERTIRES_URL} rel="noreferrer" target="_blank">
              <CooperTiresLogo className="sidebar-tires" />
            </a> */}
            <a href={process.env.REACT_APP_HOOSIER_URL} rel="noreferrer" target="_blank">
              <img src="/assets/logos/hoosier.png" height="40" />
            </a>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_VOLVO_URL} rel="noreferrer" target="_blank">
              <VolvoLogo className="sidebar-volvo" />
            </a>
          </div>
        </Col>
      </Row>
      <Row className="px-5 py-2">
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_NAGEL_URL} rel="noreferrer" target="_blank">
              <img src="/assets/logos/nagel.png" height="30" />
            </a>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_MULTITUDE_URL} rel="noreferrer" target="_blank">
              <img src="/assets/logos/multitude.png" height="10" />
            </a>
          </div>
        </Col>
      </Row>
      <Row className="px-5 py-2">
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_VINHOS_URL} rel="noreferrer" target="_blank">
              <VinhosLogo className="sidebar-vinhos" />
            </a>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_BELAS_VISTAS_URL} rel="noreferrer" target="_blank">
              <BelasVistasLogo className="sidebar-belas-vistas" />
            </a>
          </div>
        </Col>
      </Row>
      <Row className="px-5 py-2">
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_MCOUTINHO_URL} rel="noreferrer" target="_blank">
              <McoutinhoLogo className="sidebar-vinhos" />
            </a>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_SECURNET_URL} rel="noreferrer" target="_blank">
              <SecurnetLogo className="sidebar-volvo" />
            </a>
          </div>
        </Col>
      </Row>
      <Row className="px-5 py-2">
        <Col xs="12" className="d-flex flex-column justify-content-center pb-4">
          <div className="title-sponsors-sidebar-box-title skew">
            <span>Apoios</span>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_CM_MONTALEGRE_URL} rel="noreferrer" target="_blank">
              <CMMontalegreLogo className="sidebar-cm-montalegre" />
            </a>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-center py-2">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_CAVR_URL} rel="noreferrer" target="_blank">
              <CAVRLogo className="sidebar-cavr" />
            </a>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-center py-4">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_FPAK_URL} rel="noreferrer" target="_blank">
              <FPAKLogo className="sidebar-fpak" />
            </a>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-center py-4">
          <div className="sponsors-grid-row">
            <a href={process.env.REACT_APP_CI_MONTALEGRE_URL} rel="noreferrer" target="_blank">
              <CiMontalegreLogo className="sidebar-circuit" />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SponsorsBoxVertical;